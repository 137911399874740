import {Component, OnInit, Inject} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-assignment-create',
    template: `
        <h2 mat-dialog-title>Create Assignment</h2>
        <p *ngIf="data.message">{{data.message}}</p>
        <mat-form-field>
            <input matInput type="text" name="title" placeholder="Title" [(ngModel)]="title">
        </mat-form-field>
        <div class="color-selection">
            <input [(colorPicker)]="color" [style.background]="color" [cpOutputFormat]="'hex'"/>
            <span>{{color ? color : "Select a color"}}</span>
        </div>
        <div class="image-upload">
            <button type="button" mat-raised-button color="primary" (click)="logoInput.click()">Upload Logo</button>
            <input hidden type="file" #logoInput (change)="logoChange($event)">
            <span>{{logoDisplay}}</span>
        </div>
        <button mat-raised-button (click)="create()" color="primary">Create</button>
    `,
    styles: [`
        mat-form-field {
            width: 100%
        }

        p {
            margin-bottom: 1.0em !important;
        }

        .color-selection {
            margin-bottom: 1em;
            cursor: pointer;
        }

        .color-selection input {
            border-radius: 50%;
            border: 2px solid #dadada;
            height: 25px;
            width: 25px;
            cursor: pointer;
            outline: none;
        }

        .color-selection span {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.5em;
        }

        .image-upload {
            margin-bottom: 2em;
        }
    `]
})
export class AssignmentCreateComponent implements OnInit {

    public title: string;
    public logoDisplay: string;
    public logo: string;
    public color: string;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<AssignmentCreateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    create() {
        const data = new FormData();
        data.set('title', this.title);
        data.set('color', this.color ? this.color : '#000000');
        if (this.logo) {
            data.set('logo', this.logo);
        }
        this._api.createAssignment(data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close();
            }
        });
    }

    logoChange(event) {
        if (event.target.files.length) {
            this.logo = event.target.files[0];
            this.logoDisplay = event.target.files[0].name;
        }
    }

}
