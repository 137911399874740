import {AfterContentInit, OnDestroy, Component, Inject, isDevMode, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpEvent} from '@angular/common/http';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Subscription} from 'rxjs';

import {OnboardingEditDialogComponent} from './onboarding-edit-dialog.component';

import {ApiService} from '../../services/api.service';
import {HelpersService} from '../../services/helpers.service';
import {InputsService} from '../../services/inputs.service';
import {OnboardingService} from '../../services/onboarding.service';

import {PageInterface} from '../../interfaces/page.interface';
import {InputInterface} from '../../interfaces/input.interface';
import {ResponseInterface} from '../../interfaces/response.interface';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';

import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-onboarding-edit',
    templateUrl: './onboarding-edit.component.html',
    styleUrls: ['./onboarding-edit.component.sass']
})
export class OnboardingEditComponent implements OnInit, AfterContentInit, OnDestroy {

    private _assignmentId: number;
    private _isStart = false;
    private _titleIconUploaded = false;
    private _mediaUploaded = false;
    private _introAudioUploaded = false;
    private _backgroundUploaded = false;
    private _inputsSubscription: Subscription;

    @ViewChild('iframe') public iframe: ElementRef;
    public data = {} as PageInterface;
    public inputs = [];
    public waypoints = [];
    public titleIcon: any;
    public titleIconDisplay: string;
    public mediaFileDisplay: string;
    public introAudioFileDisplay: string;
    public backgroundImageDisplay: string;
    public liveReload = null;
    public isLoading = false;
    public conditional: any = false;
    public showCondition = false;
    public showInputs = false;
    public showPages = false;
    public options: any = {
        key: 'dB8C6D5E2A-9H3E2I2B2C6B3B2B5B1F1phbndxD-11A-9B1vyz==',
        required: true,
        events: {
            'froalaEditor.image.inserted': (e, editor, img) => {
                if (img.length) {
                    img[0].classList.add('content-image');
                }
            },
            'froalaEditor.blur': function (e, editor) {
                document.getElementById('froala-submit').click();
            }
        },
        toolbarButtons: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'outdent',
            'indent',
            'insertLink',
            'clearFormatting',
            'insertTable',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'formatUL',
            'formatOL',
            'html',
        ]
    };
    private _timeout: any = null;

    constructor(
        private _api: ApiService,
        private _inputs: InputsService,
        private _onboarding: OnboardingService,
        private _helpers: HelpersService,
        private _sanitizer: DomSanitizer,
        public _dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<OnboardingEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog: any,
    ) {
        this.data = dialog;
        this.data.save = false;
    }

    ngOnInit() {

        const accessToken = localStorage.getItem('access_token');
        const tokenType = localStorage.getItem('token_type');

        this.options.requestHeaders = {
            Authorization: `${tokenType} ${accessToken}`,
        };

        this.options.imageUploadURL = `${environment.apiUrl}/onboarding/editor/upload`;
        if (this.data.link) {
            this.liveReload = this._sanitizer.bypassSecurityTrustResourceUrl(this.data.link);
        }

        this.waypoints.push({
            key: 'start',
            value: 'Start'
        }, {
            key: 'end',
            value: 'End'
        });

        for (let i = 1; i <= 100; i++) {
            this.waypoints.push({
                key: i,
                value: i
            });
        }

        // Assign all variables
        this.titleIconDisplay = '';
        this.mediaFileDisplay = '';
        this.introAudioFileDisplay = '';
        this.backgroundImageDisplay = '';

        this._isStart = this.data.id === 'start';
        this._assignmentId = this.data.assignmentID;
        this.data.title = this.data.title ? this.data.title : '';
        this.data.showTitleField = !!this.data.showTitleField;
        this.data.subtitle = this.data.subtitle ? this.data.subtitle : '';
        this.data.content = this.data.content ? this.data.content : '';
        this.data.redirect = this.data.redirect ? this.data.redirect : 0;
        this.data.waypoint = this.data.waypoint ? this.data.waypoint : '';
        this.data.theme = this.data.theme ? this.data.theme : 'light';
        this.inputs = this.data.inputs ? this.data.inputs : [];
        this.data.titleIcon = this.data.titleIcon ? this.data.titleIcon : '';
        this.data.media = this.data.media ? this.data.media : '';
        this.data.background = this.data.background ? this.data.background : '';
        this.data.points = this.data.points ? this.data.points : 0;
        this.data.introAudio = this.data.introAudio ? this.data.introAudio : '';
        this.data.introAudioUrl = this.data.introAudio? this.data.introAudio : '';
        this.data.metadata = this.data.metadata ? this.data.metadata : '';

        if (this.data.titleIcon) {
            const titleIconParts = this.data.titleIcon.split('/');
            this.titleIconDisplay = titleIconParts[titleIconParts.length - 1];
        }

        if (this.data.media) {
            const mediaFileParts = this.data.media.split('/');
            this.mediaFileDisplay = mediaFileParts[mediaFileParts.length - 1];
        }

        if (this.data.introAudio) {
            const introAudioFileParts = this.data.introAudio.split('/');
            this.introAudioFileDisplay = introAudioFileParts[introAudioFileParts.length - 1];
        }

        if (this.data.background) {
            const backgroundImageParts = this.data.background.split('/');
            this.backgroundImageDisplay = backgroundImageParts[backgroundImageParts.length - 1];
        }

        this._inputsSubscription = this._inputs.inputs$.subscribe((result: InputInterface) => {

            if (!this.data.nodeId) {
                this.data.nodeId = Date.now();
            }

            const nodeId = this._isStart ? 'start' : this.data.nodeId.toString();

            if (result.target !== null) {
                const links = JSON.parse(JSON.stringify(this.data.hierarchialGraph.links));
                links.push({
                    source: nodeId,
                    target: result.target
                });

                this.data.hierarchialGraph.links = links;
            }

            if (result.options.length) {
                const links = JSON.parse(JSON.stringify(this.data.hierarchialGraph.links));

                for (let i = 0; i < result.options.length; i++) {
                    if (result.options[i].target) {
                        links.push({
                            source: nodeId,
                            target: result.options[i].target
                        });
                    }
                }

                this.data.hierarchialGraph.links = links;
            }

            if (typeof result.index === 'number') {
                const inputIndex = result.index;
                delete result.index;
                this.inputs[inputIndex] = result;
            } else {
                this.inputs.push(result);
            }
        });

    }

    ngAfterContentInit() {

        // Size the iframe
        this.resizeIframe();
    }

    ngOnDestroy() {
        this._inputsSubscription.unsubscribe();
    }

    loadDynamicContent(selectedIndex) {
        if (selectedIndex === 3) {
            this.loadConditionalTab();
        }
    }

    themeChange(value: any): void {
        this.data.theme = value;

        const $this = this;
        this.liveReloadIframe();
    }

    titleIconChange(event): void {
        if (event.target.files.length) {

            const file = event.target.files[0];
            const name = file.name;
            const filename = name.toLowerCase();
            this.data.titleIcon = file;

            // Verify media type
            if (filename.includes('jpg') || filename.includes('jpeg') || filename.includes('png') || filename.includes('gif')) {
                this.titleIconDisplay = name;
                this._titleIconUploaded = true;
                this.liveReloadIframe();
            } else {
                this.data.media = null;
                this.isLoading = false;
                this.snackBar.open('Please upload a JPG, GIF, or PNG file.', null, {
                    duration: 3000
                });
            }
        }
    }

    mediaFileChange(event): void {

        this.isLoading = true;

        if (event.target.files.length) {

            const file = event.target.files[0];
            const name = file.name;
            const filename = name.toLowerCase();
            this.data.media = file;

            // Verify media type
            if (filename.includes('mp3') || filename.includes('mp4')) {
                this.mediaFileDisplay = name;
                this._mediaUploaded = true;
                this.liveReloadIframe();
            } else {
                this.data.media = null;
                this.isLoading = false;
                this.snackBar.open('Please upload an MP3 or MP4 file.', null, {
                    duration: 3000
                });
            }
        }
    }

    introAudioFileChange(event): void {
        this.isLoading = true;

        if (event.target.files.length) {

            const file = event.target.files[0];
            const name = file.name;
            const filename = name.toLowerCase();
            this.data.introAudio = file;

            // Verify media type
            if (filename.includes('mp3') || filename.includes('mp4')) {
                this.introAudioFileDisplay = name;
                this._introAudioUploaded = true;
                this.liveReloadIframe();
            } else {
                this.data.introAudio = null;
                this.isLoading = false;
                this.snackBar.open('Please upload an MP3 or MP4 file.', null, {
                    duration: 3000
                });
            }
        }
    }

    backgroundImageChange(event): void {
        if (event.target.files.length) {

            const file = event.target.files[0];
            const name = file.name;
            const filename = name.toLowerCase();
            this.data.background = file;

            if (filename.includes('jpg') || filename.includes('jpeg') || filename.includes('png')) {
                this.backgroundImageDisplay = name;
                this._backgroundUploaded = true;
                this.liveReloadIframe();
            } else {
                this.data.media = null;
                this.isLoading = false;
                this.snackBar.open('Please upload a JPG or PNG file.', null, {
                    duration: 3000
                });
            }
        }
    }

    deleteTitleIcon(): void {
        this.data.titleIcon = '';
        this.titleIconDisplay = null;
        this.liveReloadIframe();
    }

    deleteMedia(): void {
        this.data.media = '';
        this.mediaFileDisplay = null;
        this.liveReloadIframe();
    }

    deleteIntroAudio(): void {
        this.data.introAudio = '';
        this.data.introAudioUrl = '';
        this.introAudioFileDisplay = null;
        this.liveReloadIframe();
    }

    deleteBackground(): void {
        this.data.background = '';
        this.backgroundImageDisplay = null;
        this.liveReloadIframe();
    }

    saveDialog(state: boolean): void {
        this.dialog.save = state;
        this.saveNode(() => {
            // If conditional has been set, that page was visited.  Save the conditions.
            if (this.conditional) {
                this.saveConditionalTab();
            }
            this.closeDialog();
        });
    }

    closeDialog(): void {
        this.dialogRef.close(this.dialog);
    }

    liveReloadIframe($this = null): void {

        if (!$this) {
            $this = this;
        }

        this._cancelTimeout();
        this._timeout = setTimeout(() => {

            // Save the current inputs
            this.saveNode(function () {

                // Reload the iframe
                $this.iframe.nativeElement.contentWindow.postMessage('reload', '*');
            });
        }, 3000);
    }

    /**
     * Save all inputs which have been entered
     * @param {any} callback
     */
    saveNode(callback = null) {

        // not sure best location for metadata JSON validation
        try {
            if (this.data.metadata) {
                this.data.metadata=JSON.stringify(JSON.parse(this.data.metadata), null, 4);
            }
        } catch (err) {
            this.snackBar.open(err, null, {
                duration: 3000
            });
            return;
        }

        const input = new FormData();
        input.append('showTitleField', this.data.showTitleField ? '1' : '0');
        input.append('title', this.data.title);
        input.append('subtitle', this.data.subtitle);
        input.append('content', this.data.content);
        input.append('inputs', JSON.stringify(this.inputs));
        input.append('media', this.data.media);
        input.append('titleIcon', this.data.titleIcon);
        input.append('background', this.data.background);
        input.append('redirect', this.data.redirect);
        input.append('points', this.data.points);
        input.append('waypoint', this.data.waypoint);
        input.append('theme', this.data.theme);
        input.append('introAudio', this.data.introAudio ? this.data.introAudio : this.data.introAudioUrl);
        input.append('metadata', this.data.metadata);

        this._api.updateOnboarding(this.data.nodeId, input).subscribe((event: HttpEvent<any>) => {

            // If successful, run callback
            if (callback) {
                callback();
            }

        }, error => {
            this.snackBar.open(error, null, {
                duration: 10000
            });
        });

        // Release the loader
        this.isLoading = false;
    }

    /**
     * Open the input editor
     * @param {any} input
     * @param {any} index
     */
    openInputEditDialog(input = null, index = null): void {

        const $this = this;
        let targets = [];

        // Add and sort targets
        for (let i = 0; i < this.data.hierarchialGraph.nodes.length; i++) {
            targets.push({
                id: '' + this.data.hierarchialGraph.nodes[i].real_id,
                name: this.data.hierarchialGraph.nodes[i].label
            });
        }
        targets = this._helpers.sortAnObject(targets, 'name');

        const data = {
            input,
            index,
            targets,
            assignmentID: this._assignmentId
        };

        const dialogRef = this._dialog.open(OnboardingEditDialogComponent, {
            data,
            width: '650px'
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result && Number.isInteger(result.index)) {
                const removed = this.inputs.splice(index, 1);
                const links = JSON.parse(JSON.stringify(this.data.hierarchialGraph.links));
                const nodeId = this._isStart ? 'start' : this.data.nodeId.toString();

                for (let i = 0; i < links.length; i++) {
                    const source = links[i].source;
                    const target = links[i].target;
                    let removedTarget = '';

                    if (removed[0].target) {
                        removedTarget = removed[0].target.toString();
                    }

                    if (source === nodeId && target === removedTarget) {
                        links.splice(i, 1);
                        this.data.hierarchialGraph.links = links;
                    }
                }

            }

            // Save changes to the workflow (to prevent inputs from being lost), so long as the node is not new
            if (result && result.save) {
                this.liveReloadIframe();
            }
        });
    }

    /**
     * Load the contents of the conditional tab
     */
    loadConditionalTab() {

        // Load the values if available
        this._api.getOnboardingCondition(this.data.nodeId).subscribe((res1: ResponseInterface) => {

            if (res1.success) {

                let condition: any = [];
                this.conditional = {
                    isConditional: '0'
                };

                if (res1.result.length) {
                    condition = res1.result[0];
                    this.conditional = {
                        isConditional: `${condition.is_conditional}`,
                        showHide: `${condition.show_page}`,
                        equals: `${condition.equals}`,
                        assignments: [],
                        assignmentId: condition.assignment_id,
                        inputName: condition.name,
                        inputValue: condition.value,
                        redirectId: condition.redirect_id,
                    };

                }
                this.showCondition = true;
            }

            // Load the assignments list
            this._api.getAllAssignments().subscribe((res2: ResponseInterface) => {
                if (res2.success) {
                    this.conditional.assignments = res2.result;
                }
            });

            // Load the inputs by assignment ID
            this.getInputNameConditionalTab();

            // Load all of the pages by assignment ID
            this.getPagesByCurrentAssignmentId();

        });
    }

    /**
     * Get all inputs by assignment ID
     */
    getInputNameConditionalTab() {
        const inputs = [];
        this._api.getAllInputsByAssignmentId(this._assignmentId).subscribe((response: ResponseInterface) => {
            if (response.success) {
                for (let i = 0; response.result.length > i; i++) {
                    // Remove all of the button inputs as we cannot compare those
                    if (!response.result[i].name.includes('button_')) {
                        inputs.push(response.result[i]);
                    }
                }
                this.conditional.inputs = inputs;
                this.showInputs = true;
            }
        });
    }

    getPagesByCurrentAssignmentId() {
        this._api.getOnboardingFlow(this._assignmentId).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.conditional.pages = response.result.nodes;
                this.showPages = true;
            }
        });
    }

    /**
     * Save the contents of the conditional tab
     */
    saveConditionalTab() {

        const formData = new FormData;
        formData.append('is_conditional', this.conditional.isConditional);
        formData.append('show_page', (this.conditional.showHide ? this.conditional.showHide : 0));
        formData.append('equals', (this.conditional.equals ? this.conditional.equals : 1));
        formData.append('assignment_id', `${this._assignmentId}`);
        formData.append('name', (this.conditional.inputName ? this.conditional.inputName : ''));
        formData.append('value', (this.conditional.inputValue ? this.conditional.inputValue : ''));
        formData.append('redirect_id', (this.conditional.redirectId ? this.conditional.redirectId : ''));

        // Save the condition
        this._api.setOnboardingCondition(this.data.nodeId, formData).subscribe(result => {
            this.showInputs = true;
        });
    }

    /**
     * Upon sort, re-assign the order of the inputs
     * @param {CdkDragDrop<string[]>} event
     */
    sortInput(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.inputs, event.previousIndex, event.currentIndex);

        let i = 0;

        // Get the new array order
        for (const input of this.inputs) {
            input.order = i++;
        }
        this.liveReloadIframe();
    }

    toString(int) {
        return String(int);
    }

    openLink() {
        this._dialog.open(OnboardingPageLinkDialogComponent, {
            data: {
                link: this.buildLink(this._assignmentId, this.data.nodeId)
            },
            width: '650px'
        });
    }

    resizeIframe() {
        const dialog = document.getElementsByClassName('cdk-overlay-pane')[0];
        const iframe = document.getElementById('iframe-wrapper');
        const editor = document.getElementById('editor-wrapper');

        iframe.style.height = editor.style.height = dialog.getBoundingClientRect().height + 'px';
    }

    buildLink(assignmentId: number, nodeId: any) {
        nodeId = this.data.waypoint !== 'start' ? nodeId : this.data.waypoint;
        const domain = window.location.href.indexOf('admin.elation.com') > -1 ? 'https://mycoachmalcom.com' : window.location.href.indexOf('admin.elation.dev') > -1 ? 'https://malcolm.elation.dev' : 'http://localhost:4201';
        return `${domain}/onboarding/${this._api.accountId}/${assignmentId}/${nodeId}`;
    }

    confirmDeleteNode() {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: 'node' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.deleteNode();
            }
        });
    }

    deleteNode() {
        this._api.deleteOnboarding(this.data.nodeId).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.closeDialog();
            } else {
                this.snackBar.open(response.message, null, {
                    duration: 3000
                });
            }
        });
    }

    private _cancelTimeout(): void {
        clearTimeout(this._timeout);
        this._timeout = undefined;
    }
}


@Component({
    selector: 'app-onboarding-page-link-dialog',
    template: `
        <h3>Your Page Link</h3>
        <p><a href="{{link}}" target="_blank">Click to open the page link</a></p>
        <p>
            <small>Note that if there is media on this page, the media will not automatically play when visiting it directly.</small>
        </p>
    `,
    styles: [`
        :host {
            text-align: center;
        }
        a {
            outline: none;
        }
    `]
})
export class OnboardingPageLinkDialogComponent implements OnInit {

    public data: any;
    public link = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialog: any,
        public dialogRef: MatDialogRef<OnboardingPageLinkDialogComponent>
    ) {
        this.data = dialog;
        this.link = dialog.link;
    }

    ngOnInit() {
    }
}
