import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ResponseInterface} from '../../interfaces/response.interface';

@Component({
    selector: 'app-account-image-edit',
    templateUrl: './account-image-edit.component.html',
    styles: [`
        span {
            margin-left: 1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 350px;
            display: inline-block;
        }
    `]
})
export class AccountImageEditComponent implements OnInit {

    public fileName: string;

    private image: File = null;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<AccountImageEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.fileName = this.data.logo_url;
    }

    accountImageChange(event) {
        if (event.target.files.length) {
            this.image = event.target.files[0];
            this.fileName = this.image.name;
        }
    }

    update() {
        const input = new FormData();
        input.append('logo_file', this.image, this.fileName);

        this._api.updateAccount(this.data.uid, input).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            }
        });
    }

}
