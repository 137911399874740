import {Component} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {HelpersService} from '../../services/helpers.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-users-create',
    template: `
        <h2 mat-dialog-title>Create User</h2>
        <form>
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field fxFlex="50">
                    <input matInput type="text" name="first_name" [(ngModel)]="firstName" placeholder="First Name" required>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <input matInput type="text" name="last_name" [(ngModel)]="lastName" placeholder="Last Name" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field fxFlex="50">
                    <input matInput (input)="phoneNumber = helper.formatPhoneNumber(phone.value)" #phone type="tel"
                           [value]="phoneNumber"
                           [(ngModel)]="phoneNumber"
                           name="phone_number"
                           maxlength="14"
                           placeholder="Phone Number"
                           required>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <input matInput type="email" name="email" [(ngModel)]="email" placeholder="Email" required>
                </mat-form-field>
            </div>

            <h3 mat-dialog-title>Set Password</h3>
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field fxFlex="50">
                    <input matInput type="password" name="password" [(ngModel)]="password" placeholder="Password" required>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <input matInput type="password" name="password_confirmation" [(ngModel)]="passwordConfirmation"
                           placeholder="Password Confirmation" required>
                </mat-form-field>
            </div>
            <button mat-raised-button color="primary" (click)="create()">Create</button>
        </form>
    `,
    styles: [``]
})
export class UsersCreateComponent {

    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public email: string;
    public password: string;
    public passwordConfirmation: string;

    constructor(
        public helper: HelpersService,
        private _api: ApiService,
        private _dialog: MatDialogRef<UsersCreateComponent>,
        private _snackBar: MatSnackBar
    ) {
    }

    create() {
        const data = {
            first_name: this.firstName,
            last_name: this.lastName,
            phone_number: this.phoneNumber,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation
        };

        if (this.password === this.passwordConfirmation) {
            this._api.createUser(data).subscribe((response: ResponseInterface) => {
                if (response.success) {
                    this._dialog.close(true);
                }
            });
        } else {
            this._snackBar.open('Passwords do not match.', '', {
                duration: 3500
            });
        }
    }
}
