import {Component, OnInit, AfterViewInit, OnDestroy, Renderer2, isDevMode} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LoginService} from './services/login.service';
import {Subscription} from 'rxjs';
import {ApiService} from './services/api.service';
import {UsersService} from './services/users.service';

// import 'rxjs/add/operator/filter';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    public title = 'app';
    public show: boolean;
    public class: string;
    public userName: string;
    public userId: string;
    public location: string;
    public width = '70';
    public permission = 1;

    private _loginSubscription: Subscription;

    constructor(
        public users: UsersService,
        private _api: ApiService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _renderer: Renderer2,
        private _loginService: LoginService
    ) {
    }

    ngOnInit() {

        // TODO: temp for testing, remove
        this._api.accountId = '123456';

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && !isDevMode()) {
                if (!localStorage.getItem('id')) {
                    this._router.navigate(['/login']);
                } else if (this._router.url.includes('login')) {
                    this._router.navigate(['/dashboard']);
                }
            }
        });
    }

    ngAfterViewInit() {

        // Get the current location
        this._router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {

                this.location = event.url;
                this.show = this._hideElementsByRoute(event.url);

                // Adjust the element width based on base
                if (!this._hideElementsByRoute(event.url)) {
                    this.width = '100';
                }

                // Remove the old class
                document.body.removeAttribute('class');

                // Assign the current page class to the body
                this.class = this.getClassNameFromRouter(event.url);

                if (this.class !== '') {
                    this._renderer.addClass(document.body, this.class);
                }
            }
        });

        this._loginSubscription = this._loginService.login$.subscribe((result: {
            id: string,
            account_id: string,
            first_name: string,
            last_name: string
        }) => {
            console.log(result);
            this.userName = result.first_name;
            this.userId = result.id;
            this._api.accountId = result.account_id;
        });

        this.userName = localStorage.getItem('name');
        this.userId = localStorage.getItem('id');
        this._api.accountId = localStorage.getItem('account_id');
    }

    ngOnDestroy() {
        this._renderer.removeClass(document.body, this.class);
        this._loginSubscription.unsubscribe();
    }

    getClassNameFromRouter(routeUrl: string) {
        if (routeUrl) {
            return routeUrl.split('/')[1];
        }
        return '';
    }

    getCopyrightYear() {
        return new Date().getFullYear();
    }

    logOut() {
        localStorage.removeItem('id');
        this._router.navigate(['/login']);
    }

    private _hideElementsByRoute(location: string) {
        if (
            location === '/' ||
            location.includes('login') ||
            location.includes('signup') ||
            location.includes('forgot')) {
            return false;
        }
        return true;
    }
}
