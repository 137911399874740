import {Component, Inject, OnInit} from '@angular/core';
import {ResponseInterface} from '../../interfaces/response.interface';
import {ApiService} from '../../services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-onboarding-edit-dialog-starred',
    templateUrl: './onboarding-edit-dialog-starred.component.html',
    styles: [`
        a {
            outline: none;
        }

        i.icon {
            width: 15px;
            height: 15px;
        }

        i.icon.icon-delete {
            margin-right: 0.5em;
        }

        form fieldset {
            margin-top: 1em;
        }

        form fieldset mat-form-field {
            margin-right: 0.5em;
        }

        label {
            color: #792B89;
            transform: scale(0.75);
            font-size: inherit;
            font-weight: 400;
            line-height: 1.125;
            font-family: Roboto, "Helvetica Neue", sans-serif;
        }

        .save {
            padding: 0;
        }
    `]
})
export class OnboardingEditDialogStarredComponent implements OnInit {

    public data: any;
    public id: number;
    public message: string;
    public frequency: number;
    public questions = [];
    public frequencyList = {
        0: 'Never',
        24: 'Daily',
        48: 'Every other day',
        168: 'Weekly',
        720: 'Monthly',
        8736: 'Annually'
    };

    constructor(
        private _api: ApiService,
        @Inject(MAT_DIALOG_DATA) public dialog: any,
        public dialogRef: MatDialogRef<OnboardingEditDialogStarredComponent>,
        public snackBar: MatSnackBar
    ) {
        console.log(dialog);
        this.id = dialog.id;
        this.data = dialog.feature;
    }

    ngOnInit() {
        this.message = this.data.poll_message ? this.data.poll_message : '';
        this.frequency = this.data.frequency ? this.data.frequency.toString() : 0;
        this.questions = this.data.questions ? this.data.questions : [];
    }

    addNewRow() {
        this.questions.push('');
    }

    removeRow(index: number) {
        this.questions.splice(index, 1);
    }

    schedule() {
        if (this.message && this.frequency) {
            const input = new FormData();
            input.append('poll_message', this.message);
            input.append('frequency', this.frequency.toString());

            this._api.updateStarredInputById(this.id, input).subscribe((response: ResponseInterface) => {
                console.log(response);
            });
        } else {
            this.snackBar.open('Please fill all required inputs.', null, {
                duration: 3000
            });
        }
    }

    unStar() {
        this._api.deleteStarredInputById(this.id).subscribe((response: ResponseInterface) => {
            console.log(response);
        });
    }

    createStarredQuestions() {

        const input = new FormData();
        input.append('questions', JSON.stringify(this.questions));

        this._api.createStarredQuestionsById(this.id, input).subscribe((response: ResponseInterface) => {
            console.log(response);
        });
    }

}
