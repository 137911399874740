import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ResponseInterface} from '../../interfaces/response.interface';

@Component({
    selector: 'app-user-image-edit',
    templateUrl: './user-image-edit.component.html',
    styles: [`
        span {
            margin-left: 1em;
        }
    `]
})
export class UserImageEditComponent implements OnInit {

    public fileName: string;

    private image: any;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<UserImageEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    userImageChange(event) {
        if (event.target.files.length) {
            this.image = event.target.files[0];
            this.fileName = this.image.name;
        }
    }

    update() {
        const input = new FormData();
        input.append('profile_image_url', this.image);

        this._api.updateUser(this.data, input).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            }
        });
    }

}
