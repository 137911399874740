import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MatDialog} from '@angular/material';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';

export interface LearningList {
    title: string;
    categories: any;
    publishDate: string;
    edit: number;
}

@Component({
    selector: 'app-learning',
    templateUrl: './learning.component.html',
    styleUrls: ['./learning.component.sass']
})
export class LearningComponent implements OnInit {

    public learningTableColumns: string[] = ['title', 'learningCategories', 'publish_at', 'edit'];
    public learningTable;
    public learningCount: number;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialog) {
    }

    ngOnInit() {
        this.learningResults();
    }

    learningResults() {
        this._api.getAllPosts().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.learningTable = response.result.map(learning => {
                    learning.edit = learning.id;
                    const learningCategories = [];

                    for (let i = 0; i < learning.categories.length; i++) {
                        learningCategories.push(learning.categories[i].category.title);
                    }

                    learning.learningCategories = learningCategories;

                    return learning;
                });
                this.learningCount = response.result.length;
                // this.learningTable.sort = this.sort;
            }
        });
    }

    delete(postID: number, title: string) {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._deleteLearning(postID);
            }
        });
    }

    private _deleteLearning(postID: number) {
        this._api.deletePost(postID).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.learningResults();
            }
        });
    }

}
