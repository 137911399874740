import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {InsightsComponent} from './pages/insights/insights.component';
import {LearningComponent} from './pages/learning/learning.component';
import {LearningEditComponent} from './pages/learning/learning-edit.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {OnboardingComponent} from './pages/onboarding/onboarding.component';
import {UsersComponent} from './pages/users/users.component';
import {UsersEditComponent} from './pages/users/users-edit.component';
import {AdminsComponent} from './pages/admins/admins.component';
import {AdminsEditComponent} from './pages/admins/admins-edit.component';
import {NotificationListComponent} from './pages/notification-list/notification-list.component';
import {AccountsComponent} from './pages/accounts/accounts.component';
import {AccountsEditComponent} from './pages/accounts/accounts-edit.component';
import {FeedComponent} from './pages/feed/feed.component';
import {FeedEditComponent} from './pages/feed/feed-edit.component';

const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'login', component: LoginComponent},
    {path: 'login/:string', component: LoginComponent},
    {path: 'insights', component: InsightsComponent},
    {path: 'feed', component: FeedComponent},
    {path: 'feed/:id', component: FeedEditComponent},
    {path: 'learning', component: LearningComponent},
    {path: 'learning/:id', component: LearningEditComponent},
    {path: 'notifications', component: NotificationsComponent},
    {path: 'notification-list', component: NotificationListComponent},
    {path: 'onboarding', component: OnboardingComponent},
    {path: 'admins', component: AdminsComponent},
    {path: 'admins/:id', component: AdminsEditComponent},
    {path: 'accounts', component: AccountsComponent},
    {path: 'accounts/:id', component: AccountsEditComponent},
    {path: 'users', component: UsersComponent},
    {path: 'users/:id', component: UsersEditComponent},
    {path: '**', redirectTo: '/'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
