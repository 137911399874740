import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MatDialog} from '@angular/material';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.sass']
})
export class FeedComponent implements OnInit {

    public feedTableColumns: string[] = ['title', 'feedCategories', 'publish_at', 'edit'];
    public feedTable;
    public feedCount: number;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialog) {
    }

    ngOnInit() {
        this.feedResults();
    }

    feedResults() {
        this._api.getAllFeed().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.feedTable = response.result.map(feed => {
                    feed.edit = feed.id;
                    const feedCategories = [];

                    for (let i = 0; i < feed.categories.length; i++) {
                        feedCategories.push(feed.categories[i].category.title);
                    }

                    feed.feedCategories = feedCategories;

                    return feed;
                });
                this.feedCount = response.result.length;
                // this.feedTable.sort = this.sort;
            }
        });
    }

    delete(postID: number, title: string) {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._deleteFeed(postID);
            }
        });
    }

    private _deleteFeed(postID: number) {
        this._api.deleteFeed(postID).subscribe((response: ResponseInterface) => {
            console.log(response);
            if (response.success) {
                this.feedResults();
            }
        });
    }

}
