import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ResponseInterface} from '../../../interfaces/response.interface';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-password-reset',
    template: `
        <h2 mat-dialog-title>New Password</h2>
        <div class="mat-dialog-content" fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
                <input matInput type="password" name="password" [(ngModel)]="password" placeholder="Password">
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input matInput type="password" name="password_confirmation" [(ngModel)]="confirmPassword" placeholder="Confirm Password">
            </mat-form-field>
        </div>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="reset()">Reset</button>
        </mat-dialog-actions>
    `,
    styleUrls: ['./password-reset.component.sass']
})
export class PasswordResetComponent implements OnInit {
    public password = '';
    public confirmPassword = '';
    public message;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<PasswordResetComponent>,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        console.log(this.data);
    }

    reset() {
        this.message = '';

        if (this.password === this.confirmPassword && this.password !== '') {
            const data = {
                password: this.password,
                password_confirmation: this.confirmPassword
            };

            const request = !this.data.is_admin
                ? this._api.resetUserPassword(this.data.id, data)
                : this._api.resetAdminPassword(this.data.id, data);

            request.subscribe((response: ResponseInterface) => {
                console.log(response);
                if (response.success) {
                    this._dialog.close();
                    this.message = 'Password updated';
                } else {
                    this.message = response.message;
                }
            });
        } else {
            this.message = 'Passwords do not match';
        }
        if (this.message && this.message !== '') {
            this._snackBar.open(this.message, '', {
                duration: 2000
            });
        }
    }

}
