import {Component, OnInit, ViewChild} from '@angular/core';
import {ResponseInterface} from '../../interfaces/response.interface';
import {ApiService} from '../../services/api.service';
import {MatDialog, MatDialogRef, MatSnackBar, MatSort} from '@angular/material';
import {AdminsCreateComponent} from '../admins/admins.component';
import {UsersCreateComponent} from '../users/users-create.component';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';
import {AssignmentsService} from '../../services/assignments.service';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.sass']
})
export class AccountsComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    public accountTableColumns: string[] = ['title', 'edit'];
    public accountTable;
    public accountCount: number;
    public accountActive = null;

    constructor(
        public assignment: AssignmentsService,
        private _api: ApiService,
        private _dialog: MatDialog,
        private _snackbar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.accountActive = this._api.accountId;
        this._getAccounts();
    }

    create() {
        const dialogRef = this._dialog.open(AccountsCreateComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._getAccounts();
            }
        });
    }

    delete(unique_id: any, title: string) {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                console.log(unique_id);
                this._api.deleteAccount(unique_id).subscribe((response: ResponseInterface) => {
                    console.log(response);
                    if (response.success) {
                        this._getAccounts();
                    }
                });
            }
        });
    }

    switchAccount(unique_id: string) {

        // Update the local vars and storage
        this._api.accountId = this.accountActive = unique_id;
        localStorage.setItem('account_id', unique_id);

        // Get the account data
        const account = this.accountTable.filter(function (result) {
                return result.unique_id === unique_id;
        })[0];

        // Clear the active assignment out
        this.assignment.currentAssignment = 0;
        this.assignment.activeAssignment = null;
        this.assignment.assignments = [];
        this.assignment.init();

        // Notify the user
        this._snackbar.open(`Switched to "${account.title}"`, '', {
            duration: 3500
        });
    }

    private _getAccounts() {
        this._api.getAccounts().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.accountTable = response.result;
                this.accountCount = response.result.length;
                this.accountTable.sort = this.sort;
            }
        });
    }
}

@Component({
    selector: 'app-accounts-create',
    template: `
        <h2 mat-dialog-title>Create Account</h2>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex>
                <input matInput type="text" name="title" [(ngModel)]="title" placeholder="Account Title">
            </mat-form-field>
        </div>

        <div class="image-upload">
            <button type="button" mat-raised-button color="accent" (click)="logoInput.click()">
                Logo Image (optional)
            </button>
            <input hidden type="file" #logoInput (change)="logoChange($event)">
            <span>{{ logoDisplay }}</span>
        </div>

        <button mat-raised-button color="primary" (click)="create()">Create</button>
    `,
    styles: [`
        span {
            margin-left: 1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 350px;
            display: inline-block;
        }
        .image-upload {
            margin-bottom: 1em;
        }
    `]
})
export class AccountsCreateComponent {

    public title: string;
    public logoFile: any;
    public logoDisplay;

    private _logoUploaded = false;

    constructor(
        private _api: ApiService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialogRef<AccountsCreateComponent>,
    ) {
    }

    create() {

        const input = new FormData();
        input.append('title', this.title);

        if (this.logoFile) {
            input.append('logo_file', this.logoFile);
        }

        this._api.createAccount(input).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._snackBar.open(this.title + ' was successfully created.', '', {
                    duration: 3500
                });
                this._dialog.close(true);
            }
        });
    }

    logoChange(event): void {
        if (event.target.files.length) {

            const file = event.target.files[0];
            const name = file.name;
            const filename = name.toLowerCase();

            this.logoFile = file;

            // Verify media type
            if (filename.includes('jpg') || filename.includes('jpeg') || filename.includes('png') || filename.includes('gif')) {
                this.logoDisplay = name;
                this._logoUploaded = true;
            } else {
                this.logoFile = null;
                // this.isLoading = false;
                this._snackBar.open('Please upload a JPG, GIF, or PNG file.', null, {
                    duration: 3000
                });
            }
        }
    }
}