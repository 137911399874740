import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    public accountId = localStorage.getItem('account_id');

    private readonly _BASE_URL = environment.apiUrl;
    private _httpOptions: object;

    constructor(
        private _http: HttpClient
    ) {
        const accessToken = localStorage.getItem('access_token');
        const tokenType = localStorage.getItem('token_type');

        this._httpOptions = {
            headers: new HttpHeaders({
                'Authorization': tokenType + ' ' + accessToken
            })
        };
    }

    adminLogin(email, password) {
        return this._http.post(this._BASE_URL + '/authentication/admin', {
            email,
            password
        });
    }

    getActiveUsers() {
        return this._http.get(this._BASE_URL + `/reporting/active/users/account/${this.accountId}`);
    }

    getActiveCourses() {
        return this._http.get(this._BASE_URL + `/reporting/active/courses/account/${this.accountId}`);
    }

    getFeedCategories() {
        return this._http.get(this._BASE_URL + `/categories/account/${this.accountId}`);
    }

    createFeed(data: any) {
        return this._http.post(this._BASE_URL + '/feed', data);
    }

    updateFeed(feedID: number, data: any) {
        return this._http.post(this._BASE_URL + '/feed/' + feedID, data);
    }

    getAllFeed() {
        return this._http.get(this._BASE_URL + `/feed/account/${this.accountId}`);
    }

    getFeed(feedID: number) {
        return this._http.get(this._BASE_URL + `/feed/account/${this.accountId}/${feedID}`);
    }

    deleteFeed(postID: number) {
        return this._http.delete(this._BASE_URL + '/feed/' + postID);
    }

    getAllPosts() {
        return this._http.get(this._BASE_URL + `/learning/account/${this.accountId}`);
    }

    getLearningCategories() {
        return this._http.get(this._BASE_URL + `/categories/account/${this.accountId}`);
    }

    createLearning(data: any) {
        return this._http.post(this._BASE_URL + '/learning', data);
    }

    updateLearning(learningID: number, data: any) {
        return this._http.post(this._BASE_URL + '/learning/' + learningID, data);
    }

    getPost(learningID: number) {
        return this._http.get(this._BASE_URL + `/learning/account/${this.accountId}/${learningID}`);
    }

    createCategory(title: string) {
        return this._http.put(this._BASE_URL + '/categories', {title: title});
    }

    deletePost(postID: number) {
        return this._http.delete(this._BASE_URL + '/learning/' + postID);
    }

    getUsers(adminId: any) {
        let request;

        // If an ID is specified, then get all users who are students by ID
        if (adminId) {
            request = this._BASE_URL + `/users/students/${adminId}`;
        } else {
            request = this._BASE_URL + `/users/account/${this.accountId}`;
        }
        return this._http.get(request, this._httpOptions);
    }

    getUser(uniqueId: string) {
        return this._http.get(this._BASE_URL + '/users/' + uniqueId, this._httpOptions);
    }

    updateUser(uniqueId: string, data: any) {
        return this._http.post(this._BASE_URL + '/users/' + uniqueId, data, this._httpOptions);
    }

    userPasswordReset(email: string) {
        return this._http.get(this._BASE_URL + '/users/password/reset/' + email);
    }

    deleteUser(uniqueId: string) {
        return this._http.delete(this._BASE_URL + '/users/' + uniqueId, this._httpOptions);
    }

    createUser(data: { first_name: string, last_name: string, phone_number: string, email: string, password: string }) {
        return this._http.post(this._BASE_URL + '/users', data);
    }

    getAdmins() {
        return this._http.get(this._BASE_URL + '/admins', this._httpOptions);
    }

    getAdmin(uniqueId: string) {
        return this._http.get(this._BASE_URL + '/admins/' + uniqueId, this._httpOptions);
    }

    getAdminsByAccount(accountId: string) {
        return this._http.get(this._BASE_URL + `/admins/account/${accountId}`, this._httpOptions);
    }

    createAdmin(data: { account_id: string, first_name: string, last_name: string, phone_number: string, email: string, password: string }) {
        data.account_id = this.accountId;
        return this._http.put(this._BASE_URL + '/admins', data);
    }

    updateAdmin(data: any) {
        data.account_id = this.accountId;
        return this._http.post(this._BASE_URL + '/admins/' + this.accountId, data, this._httpOptions);
    }

    deleteAdmin(uniqueId: string) {
        return this._http.delete(this._BASE_URL + '/admins/' + uniqueId, this._httpOptions);
    }

    resetAdminPassword(unique_id: string, data: { password: string, password_confirmation: string }) {
        return this._http.post(this._BASE_URL + '/admins/' + unique_id + '/password/reset', data);
    }

    createInsight(data: { assignment_id: number, title: string, description: string, content: string, iconset: string, color: string }) {
        return this._http.put(this._BASE_URL + '/insights', data);
    }

    createChildInsight(insightID: number, data: { assignment_id: number, title: string, description: string, content: string, iconset: string, color: string }) {
        return this._http.put(this._BASE_URL + '/insights/' + insightID + '/sub', data);
    }

    updateInsight(insightID: number, data: { title: string, description: string, content: string, iconset: string, color: string }) {
        return this._http.post(this._BASE_URL + '/insights/' + insightID, data);
    }

    updateChildInsight(parentID: number, insightID: number, data: { title: string, description: string, content: string, iconset: string, color: string }) {
        return this._http.post(this._BASE_URL + '/insights/' + parentID + '/sub/' + insightID, data);
    }

    deleteInsight(insightID: number) {
        return this._http.delete(this._BASE_URL + '/insights/' + insightID);
    }

    createAssignment(data: FormData) {
        data.set('account_id', this.accountId);
        return this._http.post(this._BASE_URL + '/assignments', data);
    }

    duplicateAssignment(assignmentID: number, data: { title: string }) {
        return this._http.put(this._BASE_URL + '/assignments/' + assignmentID, data);
    }

    getAllAssignments() {
        return this._http.get(`${this._BASE_URL}/assignments/${this.accountId}`);
    }

    getAllInputsByAssignmentId(assignmentId: number) {
        return this._http.get(`${this._BASE_URL}/assignments/${assignmentId}/inputs`);
    }

    getAllNodesByAssignmentId(assignmentId: number) {
        return this._http.get(`${this._BASE_URL}/assignments/${assignmentId}/nodes`);
    }

    getInsightsByAssignment(assignmentID: number) {
        return this._http.get(`${this._BASE_URL}/insights/assignments/account/${this.accountId}/${assignmentID}`);
    }

    getAllInsights(): Observable<any> {
        return this._http.get(`${this._BASE_URL}/insights/account/${this.accountId}`);
    }

    getOnboardingFlow(assignmentID: number) {
        return this._http.get(this._BASE_URL + '/onboarding/' + assignmentID);
    }

    updateOnboarding(onboardingID: number, data: FormData) {
        return this._http.post(this._BASE_URL + '/onboarding/nodes/' + onboardingID, data);
    }

    postMedia(data: FormData) {
        return this._http.post(this._BASE_URL + '/media/upload/', data);
    }

    userAssignments(uniqueId: string) {
        return this._http.get(this._BASE_URL + '/users/' + uniqueId + '/assignments');
    }

    deleteOnboarding(onboardingID: number) {
        return this._http.delete(this._BASE_URL + '/onboarding/' + onboardingID);
    }

    createOnboarding(data: FormData) {
        return this._http.post(this._BASE_URL + '/onboarding/nodes', data);
    }

    getOnboardingCondition(onboardingId: number) {
        return this._http.get(this._BASE_URL + `/onboarding/condition/${onboardingId}`);
    }

    setOnboardingCondition(onboardingId: number, data: FormData) {
        return this._http.post(this._BASE_URL + `/onboarding/condition/${onboardingId}`, data);
    }

    deleteAssignment(assignmentID: number) {
        return this._http.delete(this._BASE_URL + '/assignments/' + assignmentID);
    }

    addUserAssignment(uniqueId: string, data: { assignments: number[] }) {
        return this._http.put(this._BASE_URL + '/users/' + uniqueId + '/assignments', data);
    }

    resetUserPassword(userID: number, data: { password: string, password_confirmation: string }) {
        return this._http.post(this._BASE_URL + '/users/' + userID + '/admin/password/reset', data);
    }

    getInputs(assignmentID: number) {
        return this._http.get(this._BASE_URL + '/onboarding/' + assignmentID + '/inputs');
    }

    getCoachesByUser(userId: string) {
        return this._http.get(this._BASE_URL + '/admins/assign/' + userId);
    }

    /**
     * Starred Inputs
     */
    createStarredInput(data: FormData) {
        return this._http.post(this._BASE_URL + '/onboarding/starred', data);
    }

    updateStarredInputById(inputId: number, data: FormData) {
        return this._http.put(this._BASE_URL + '/onboarding/starred/' + inputId, data);
    }

    deleteStarredInputById(inputId: number) {
        return this._http.delete(this._BASE_URL + '/onboarding/starred/' + inputId);
    }

    createStarredQuestionsById(inputId: number, data: FormData) {
        return this._http.post(this._BASE_URL + '/onboarding/starred/questions/' + inputId, data);
    }

    deleteStarredQuestionsById(inputId: number) {
        return this._http.delete(this._BASE_URL + '/onboarding/starred/questions/' + inputId);
    }

    /**
     * Assign a user to a coach.  Data should include an array of admin IDs
     * @param admin_id
     * @param {string} user_id
     * @returns {Observable<Object>}
     */
    createUserOnAdmin(userId: string, data: any) {
        return this._http.put(this._BASE_URL + '/admins/assign/' + userId, data);
    }

    getInBodyResponses() {
        return this._http.get(this._BASE_URL + '/inbody/responses');
    }

    getCoaches() {
        return this._http.get(this._BASE_URL + '/admins/coaches');
    }

    /**
     * Notifications
     */
    createNotification(data: any): Observable<any> {
        return this._http.put(this._BASE_URL + '/notifications', data, this._httpOptions);
    }

    getNotifications(): Observable<any> {
        return this._http.get(this._BASE_URL + '/notifications', this._httpOptions);
    }

    deleteNotification(notificationId: number): Observable<any> {
        return this._http.delete(this._BASE_URL + '/notifications/' + notificationId, this._httpOptions);
    }

    getNotification(notificationId: number): Observable<any> {
        return this._http.get(this._BASE_URL + '/notifications/' + notificationId, this._httpOptions);
    }

    editNotification(notificationId: number, data: any): Observable<any> {
        return this._http.post(this._BASE_URL + '/notifications/' + notificationId, data, this._httpOptions);
    }

    /**
     * Accounts
     */
    getAccounts() {
        return this._http.get(this._BASE_URL + '/accounts', this._httpOptions);
    }

    getAccount(uniqueId: string) {
        return this._http.get(this._BASE_URL + '/accounts/' + uniqueId, this._httpOptions);
    }

    createAccount(data: FormData) {
        return this._http.post(this._BASE_URL + '/accounts', data, this._httpOptions);
    }

    updateAccount(uniqueId: string, data: FormData) {
        return this._http.post(this._BASE_URL + '/accounts/update/' + uniqueId, data, this._httpOptions);
    }

    deleteAccount(uniqueId: string) {
        return this._http.delete(this._BASE_URL + '/accounts/' + uniqueId);
    }

    addUserToAccount() {

    }

    deleteUserFromAccount() {

    }
}
