import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';

// Components
import {AppComponent} from './app.component';
import {LoginComponent, LoginErrorComponent} from './pages/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AdminsComponent, AdminsCreateComponent} from './pages/admins/admins.component';
import {AdminsEditComponent, AdminsEditImageComponent, AdminsEditMetaComponent} from './pages/admins/admins-edit.component';
import {AccountsComponent, AccountsCreateComponent} from './pages/accounts/accounts.component';
import {AccountsEditComponent} from './pages/accounts/accounts-edit.component';
import {AccountImageEditComponent} from './pages/accounts/account-image-edit.component';
import {UsersComponent} from './pages/users/users.component';
import {UsersEditComponent} from './pages/users/users-edit.component';
import {LearningComponent} from './pages/learning/learning.component';
import {LearningEditComponent} from './pages/learning/learning-edit.component';
import {InsightsComponent} from './pages/insights/insights.component';
import {OnboardingComponent, OnboardingCreateNodeComponent, OnboardingLinkDialogComponent} from './pages/onboarding/onboarding.component';
import {OnboardingEditComponent, OnboardingPageLinkDialogComponent} from './pages/onboarding/onboarding-edit.component';
import {OnboardingEditDialogStarredComponent} from './pages/onboarding/onboarding-edit-dialog-starred.component';
import {OnboardingEditDialogComponent} from './pages/onboarding/onboarding-edit-dialog.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {InsightCreateComponent} from './pages/insights/insight-create.component';
import {UsersEditMetaComponent} from './pages/users/users-edit-meta.component';
import {LoginService} from './services/login.service';
import {AssignmentsComponent} from './pages/assignments/assignments.component';
import {AssignmentCreateComponent} from './pages/assignments/assignment-create.component';
import {AssignmentDuplicateComponent} from './pages/assignments/assignment-duplicate.component';
import {AssignmentsService} from './services/assignments.service';
import {UsersCreateComponent} from './pages/users/users-create.component';
import {UserImageEditComponent} from './pages/users/user-image-edit.component';
import {ConfirmDeleteComponent} from './components/confirm-delete/confirm-delete.component';
import {ConfirmCreateComponent} from './components/confirm-create/confirm-create.component';
import {CreateAssignmentComponent} from './components/create-assignment/create-assignment.component';
import {PasswordResetComponent} from './pages/users/password-reset/password-reset.component';
import {OnboardingContentComponent} from './components/onboarding-content/onboarding-content.component';
import {CreateCategoryComponent} from './components/create-category/create-category.component';

// Services
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './services/api.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputsService} from './services/inputs.service';
import {OnboardingService} from './services/onboarding.service';
import {HelpersService} from './services/helpers.service';

// Pipes
import {KeysPipe} from './pipes/keys.pipe';
import {TelPipe} from './pipes/tel.pipe';

// Flex UI
import {FlexLayoutModule} from '@angular/flex-layout';

// Angular Material
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatCheckboxModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatSlideToggleModule} from '@angular/material';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OverlayContainer} from '@angular/cdk/overlay';
import {DragDropModule} from '@angular/cdk/drag-drop';

import 'hammerjs';

// WYSIWYG
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';

// Graphing
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxGraphModule} from '@swimlane/ngx-graph';

// Other Forms
import {ColorPickerModule} from 'ngx-color-picker';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {NotificationListComponent} from './pages/notification-list/notification-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {FeedComponent} from './pages/feed/feed.component';
import {FeedEditComponent} from './pages/feed/feed-edit.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoginErrorComponent,
        DashboardComponent,
        AdminsComponent,
        AdminsEditComponent,
        AdminsEditMetaComponent,
        AdminsEditImageComponent,
        AdminsCreateComponent,
        AccountsComponent,
        AccountsCreateComponent,
        AccountsEditComponent,
        AccountImageEditComponent,
        UsersComponent,
        UsersEditComponent,
        LearningComponent,
        LearningEditComponent,
        InsightsComponent,
        OnboardingComponent,
        OnboardingEditComponent,
        OnboardingPageLinkDialogComponent,
        OnboardingEditDialogComponent,
        OnboardingEditDialogStarredComponent,
        OnboardingLinkDialogComponent,
        OnboardingCreateNodeComponent,
        OnboardingContentComponent,
        NotificationsComponent,
        InsightCreateComponent,
        UsersEditMetaComponent,
        AssignmentsComponent,
        AssignmentCreateComponent,
        AssignmentDuplicateComponent,
        UsersCreateComponent,
        UserImageEditComponent,
        ConfirmDeleteComponent,
        ConfirmCreateComponent,
        CreateAssignmentComponent,
        PasswordResetComponent,
        KeysPipe,
        TelPipe,
        NotificationListComponent,
        FeedComponent,
        FeedEditComponent,
        CreateCategoryComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        FlexLayoutModule,
        BrowserAnimationsModule,

        MatInputModule,
        MatButtonModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        MatExpansionModule,
        MatSelectModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatIconModule,
        DragDropModule,

        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        NgxChartsModule,
        NgxGraphModule,
        ColorPickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxPaginationModule,
    ],
    providers: [
        ApiService,
        InputsService,
        OnboardingService,
        LoginService,
        AssignmentsService,
        HelpersService,
        OverlayContainer,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        LoginErrorComponent,
        OnboardingEditComponent,
        OnboardingEditDialogComponent,
        OnboardingEditDialogStarredComponent,
        OnboardingLinkDialogComponent,
        OnboardingCreateNodeComponent,
        OnboardingPageLinkDialogComponent,
        AccountsCreateComponent,
        AccountImageEditComponent,
        InsightCreateComponent,
        UsersEditMetaComponent,
        AssignmentCreateComponent,
        AssignmentDuplicateComponent,
        UsersCreateComponent,
        UserImageEditComponent,
        AdminsCreateComponent,
        AdminsEditMetaComponent,
        AdminsEditImageComponent,
        ConfirmDeleteComponent,
        ConfirmCreateComponent,
        PasswordResetComponent,
        OnboardingContentComponent,
        CreateCategoryComponent
    ]
})

export class AppModule {
}
