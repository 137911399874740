import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {InputsService} from '../../services/inputs.service';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';
import {HelpersService} from '../../services/helpers.service';
import {MatCheckboxChange} from '@angular/material/typings/esm5/checkbox';
import {OnboardingEditDialogStarredComponent} from './onboarding-edit-dialog-starred.component';

@Component({
    selector: 'app-onboarding-edit-dialog',
    templateUrl: './onboarding-edit-dialog.component.html',
    styleUrls: ['./onboarding.component.sass']
})
export class OnboardingEditDialogComponent implements OnInit {

    public index: number;
    public inputId: number;
    public inputs = [];
    public nodesById;
    public inputsById;
    public type: string;
    public label = '';
    public limit = 0;
    public starred = [];
    public attributes: {
        color: string,
        iconPath: string,
        label: string,
        name: string,
        target: string,
        format: string,
        compared: string,
        value: string,
        prefix: string,
        postfix: string,
        points: number,
        emailTemplate: string,
        emailTarget: string,
    }[];
    public options: {
        label: string,
        name: string,
        target: string,
        value: string,
        prefix: string,
        postfix: string,
        points: number,
        default: boolean
    }[];
    public textarea = '';
    public value = '';
    public points = 0;
    public prefix = null;
    public postfix = null;
    public mediaFile: string;
    public mediaFileDisplay: string;
    public mediaUploaded = false;
    public mediaTarget: string;
    public name: string;
    public inBodyResponses = [];
    public format = '';
    public data: any;
    public omit: boolean;
    public required: boolean;
    public bypass: boolean;
    public coaches = [];
    public categories = [];
    public assignments = [];
    public formats = [
        {
            label: 'Text',
            value: 'text'
        },
        {
            label: 'Number-Only',
            value: 'number'
        },
        {
            label: 'Email Address',
            value: 'email'
        },
        {
            label: 'Telephone Number',
            value: 'tel'
        },
        {
            label: 'Date',
            value: 'date'
        }
    ];
    public targetsCurrent = [];
    public targetGroups = [];
    public targets = [];
    public target: number | string;
    public targetPlaceholder = 'Select an assignment';
    public assignmentPlaceholder = 'Loading';
    public compares = [];
    public compared = '';
    public showComparedInputs = false;
    public disabledComparedInput = false;
    public comparedAssignmentId;
    public sections = [
        {
            value: 'exercise-network',
            title: 'Exercise Network'
        },
        {
            value: 'upcoming',
            title: 'Upcoming'
        },
        {
            value: 'tools',
            title: 'Tools'
        },
    ];
    public emailTemplate: string;
    public emailTarget: string;

    private _assignmentID: number;
    private _inputTypeData = [
        {
            type: 'button',
            name: 'Button',
            allowed: ['label', 'name', 'target', 'bypass']
        },
        {
            type: 'break',
            name: 'Break',
            allowed: []
        },
        {
            type: 'select',
            name: 'Select',
            allowed: ['label', 'name', 'options', 'options.label', 'options.points', 'options.default', 'required']
        },
        {
            type: 'radio',
            name: 'Radio',
            allowed: ['label', 'type', 'name', 'options', 'options.label', 'options.target', 'options.points', 'required']
        },
        {
            type: 'text',
            name: 'Text',
            allowed: ['label', 'name', 'format', 'email-template', 'email-target', 'prefix', 'points', 'required']
        },
        {
            type: 'textarea',
            name: 'Textarea',
            allowed: ['label', 'name', 'limit', 'points', 'required']
        },
        {
            type: 'comparison',
            name: 'Comparison',
            allowed: ['label', 'compared-multiple']
        },
        {
            type: 'checkbox',
            name: 'Checkbox',
            allowed: ['label', 'options', 'options.label', 'options.points', 'required']
        },
        {
            type: 'schedule',
            name: 'Schedule',
            allowed: ['compared-multiple', 'target']
        },
        {
            type: 'range',
            name: 'Range',
            allowed: ['label', 'required']
        },
        {
            type: 'insights',
            name: 'Insights',
            allowed: []
        },
        {
            type: 'invitation',
            name: 'Invitation',
            allowed: ['compared']
        },
        {
            type: 'coaches',
            name: 'Coaches',
            allowed: ['coaches']
        },
        {
            type: 'results',
            name: 'Results',
            allowed: []
        },
        {
            type: 'inbody_login',
            name: 'InBody Login',
            allowed: ['target']
        },
        {
            type: 'inbody_response',
            name: 'InBody Response',
            allowed: ['inbody.response', 'label', 'postfix']
        },
        {
            type: 'inbody_results',
            name: 'InBody Results',
            allowed: []
        },
        {
            type: 'login',
            name: 'Login',
            allowed: ['target']
        },
        {
            type: 'graph_horizontal_bar',
            name: 'Graph (Horizontal Bar)',
            allowed: ['label', 'compared-multiple']
        },
        {
            type: 'slider',
            name: 'Slider',
            allowed: ['label', 'options', 'options.label', 'options.points']
        },
        {
            type: 'sortable',
            name: 'Sortable',
            allowed: ['label', 'compared-multiple', 'options', 'options.label']
        }
    ];

    constructor(
        public dialogRef: MatDialogRef<OnboardingEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog: any,
        private _inputs: InputsService,
        private _api: ApiService,
        private _dialog: MatDialog,
        private _helpers: HelpersService) {
        this.data = dialog;
    }

    ngOnInit() {

        // Set all of the input variables
        this.initialize();

        this._getInputValuesByApi();

        // Get the inputs by the current target
        this.showInputsByCurrentAssignment();
    }

    initialize() {
        this.options = [];

        if (this.data.assignmentID) {
            this._assignmentID = this.data.assignmentID;
        }

        if (this.data.input) {

            if (this.data.input.id) {
                this.inputId = this.data.input.id;
            }

            if (this.data.input.type) {
                this.type = this.data.input.type;
            }

            if (this.data.input.name) {
                this.name = this.data.input.name;
            }

            if (this.data.input.label) {
                this.label = this.data.input.label;
            }

            if (this.data.input.value) {
                if (this.type === 'coaches' || this.type === 'dashboard') {
                    this.value = JSON.parse(this.data.input.value);
                } else {
                    this.value = this.data.input.value;
                }
            }

            if (this.data.input.points) {
                this.points = this.data.input.points;
            }

            if (this.data.input.prefix) {
                this.prefix = this.data.input.prefix;
            }

            if (this.data.input.postfix) {
                this.postfix = this.data.input.postfix;
            }

            if (this.data.input.attributes && this.data.input.attributes.length) {
                this.attributes = this.data.input.attributes;
            }

            if (this.data.input.format) {
                this.format = this.data.input.format;
            }

            if (this.data.input.compared) {
                this.compared = this.data.input.compared;

                if (this._isStringJson(this.compared)) {
                    this.compared = JSON.parse(this.compared);
                }
            }

            // If an assignment ID is set, then get the compared input, and the respective assignment ID
            this.comparedAssignmentId = this.data.input.compared_assignment_id
                ? this.data.input.compared_assignment_id
                : this._assignmentID;

            // If the response is numeric, then update the var
            if (this._isNumeric(this.comparedAssignmentId)) {
                this.comparedAssignmentId = +this.comparedAssignmentId;
            }

            this.showInputsByAssignment(this.comparedAssignmentId, true);

            if (this.data.input.options) {
                this.options = this.data.input.options;
            }

            if (this.data.input.target) {

                this.target = !this._isNumeric(this.data.input.target)
                    ? this.data.input.target.toString()
                    : +this.data.input.target;

                // If the string is an S3 link, then it's a media file
                if (this.target.toString().includes('.s3.')) {
                    this.mediaTarget = this.target.toString();
                    this.mediaFileDisplay = this.target.toString().split('/').pop();
                    this.target = '0';
                }
            }

            if (this.data.input.omit) {
                this.omit = this.data.input.omit;
            }

            if (this.data.input.required) {
                this.required = this.data.input.required;
            }

            if (this.data.input.bypass) {
                this.bypass = this.data.input.bypass;
            }

            if (this.data.input.email_template) {
                this.emailTemplate = this.data.input.email_template;
            }

            if (this.data.input.email_target) {
                this.emailTarget = this.data.input.email_target;
            }
        }
        this.targets = this.data.targets;
    }

    /**
     * Determines whether an input should be allowed by type
     *
     * @param typeSelected
     * @param inputType
     * @returns {boolean}
     */
    isInputAllowedByType(typeSelected, inputType) {
        if (typeof typeSelected === 'undefined') {
            return;
        }

        const allowed = this.getInputObject();
        const input = allowed.find(result => result.type === typeSelected);

        if (typeof input !== 'undefined' && input.allowed.indexOf(inputType) !== -1) {

            return true;
        }
        return false;
    }

    createNewOption() {
        this.options.push({
            // color: '',
            // iconPath: '',
            label: '',
            name: '',
            target: '',
            value: '',
            prefix: '',
            postfix: '',
            points: 0,
            default: false
        });
    }

    selectDefaultChange(value: MatCheckboxChange, optionIndex: number) {
        if (value.checked) {
            this.options.map((option: any, index: number) => {
                if (index !== optionIndex) {
                    option.default = false;
                }

                return option;
            });
        }
    }

    /**
     * Update inputs in the current flow
     */
    updateFlowInput(): void {

        const data = {
            index: null,
            type: this.type,
            name: this.name,
            label: this.label,
            limit: this.limit,
            attributes: this.attributes,
            options: this.options,
            textarea: this.textarea,
            target: this.mediaTarget ? this.mediaTarget : this.target,
            value: this.value,
            format: this.format,
            compared: this.compared,
            compared_assignment_id: this.comparedAssignmentId,
            prefix: this.prefix,
            postfix: this.postfix,
            points: this.points,
            omit: this.omit,
            required: this.required,
            bypass: this.bypass,
            email_template: this.emailTemplate,
            email_target: this.emailTarget,
        };

        // if (this.type === 'coaches' || this.type === 'dashboard') {
        if (typeof data.value === 'object') {
            data.value = JSON.stringify(data.value);
        }

        if (typeof data.compared === 'object') {
            data.compared = JSON.stringify(data.compared);
        }

        if (typeof this.data.index === 'number') {
            data.index = this.data.index;
            this._inputs.update(data);
        } else {
            this._inputs.add(data);
        }

        this.dialogRef.close({
            save: true
        });
    }

    /**
     * Confirm deletion of a page
     */
    delete(): void {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: 'input' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.dialogRef.close({
                    index: this.data.index,
                    save: true
                });
            }
        });
    }

    /**
     * Change a media file
     * @param event
     */
    mediaFileChange(event): void {
        if (event.target.files.length) {

            this.mediaFile = event.target.files[0];
            this.mediaFileDisplay = event.target.files[0].name;
            this.mediaUploaded = true;

            const input = new FormData();
            input.append('media', this.mediaFile);

            // Upload the file to S3
            this._api.postMedia(input).subscribe((data: ResponseInterface) => {
                if (data.success) {
                    // Assign the S3 link to the target
                    this.mediaTarget = data.result;
                }
            });

        }
    }

    /**
     * Reset the media target variable
     */
    resetMediaTarget(): void {
        this.mediaTarget = null;
    }

    /**
     * Get an input object
     * @returns {any}
     */
    getInputObject() {
        return this._helpers.sortAnObject(this._inputTypeData, 'name');
    }

    /**
     * Open the feature dialog box
     */
    openFeature(): void {
        const starred = !this.starred.length ? null : this.starred[0];
        this._dialog.open(OnboardingEditDialogStarredComponent, {
            data: {
                id: this.inputId,
                feature: starred
            },
            width: '650px'
        });
    }

    /**
     * Remove an option row
     * @param index
     */
    removeRow(index) {
        this.options.splice(index, 1);
    }

    showNodesByAssignment(assignmentId) {

        this.targetPlaceholder = 'Loading';

        if (assignmentId !== '0' && assignmentId !== 'dashboard') {
            this._api.getAllNodesByAssignmentId(assignmentId).subscribe((response: ResponseInterface) => {
                if (response.success) {
                    this.targetPlaceholder = 'Targets';
                    this.targets = response.result;
                    this._getTargetGroupInputs(this.inputs, this.targets);
                }
            });
        }
    }

    /**
     * Retrieve all of the inputs by a given assignment
     * @param value
     */
    showInputsByAssignment(assignmentId, updateNodes = false) {

        this.showComparedInputs = false;

        // If the assignment ID is numeric, then it is a true assignment ID
        if (typeof assignmentId !== 'undefined' && assignmentId !== 0 && assignmentId !== 'dashboard') {
            this.showComparedInputs = true;
            this.disabledComparedInput = true;

            // Retrieve all inputs by the assignment ID
            this._api.getAllInputsByAssignmentId(assignmentId).subscribe((response: ResponseInterface) => {
                if (response.success) {
                    this.disabledComparedInput = false;
                    this.inputs = response.result;
                    this._getTargetGroupInputs(this.inputs);

                    if (updateNodes) {
                        this.showNodesByAssignment(assignmentId);
                    }
                }
            });
        }
    }

    showInputsByCurrentAssignment() {
        this._api.getOnboardingFlow(this._assignmentID).subscribe((response: ResponseInterface) => {

            if (response.success) {
                const result = response.result.nodes;

                for (let i = 0; result.length > i; i++) {
                    this.targetsCurrent.push({
                        id: result[i].real_id,
                        name: result[i].label
                    });
                }
            }
        });
    }

    /**
     * Get any responses from InBody to fill inputs
     * @private
     */
    private _getInputValuesByApi() {

        // Get InBody if required
        if (this.isInputAllowedByType(this.type, 'inbody')) {
            this._api.getInBodyResponses().subscribe((res: ResponseInterface) => {
                if (res.success) {
                    this.inBodyResponses = res.result;
                }
            });
        }

        this._api.getCoaches().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.coaches = response.result;
            }
        });

        this._api.getAllAssignments().subscribe((res: ResponseInterface) => {
            if (res.success) {
                this.assignments = res.result;
                this.assignmentPlaceholder = 'Select a target or assignment';
            }
        });

        this._api.getLearningCategories().subscribe((res: ResponseInterface) => {
            if (res.success) {
                this.categories = res.result;
            }
        });
    }

    // private _getCurrentInputs() {
    //     this._api.getInputs(this._assignmentID).subscribe((response: ResponseInterface) => {
    //
    //         if (response.success) {
    //
    //             const $this = this;
    //
    //             // Retrieve starred data
    //             this.starred = response.result.filter(function (value: { id: number; poll_message: string }) {
    //                 return value.id === $this.inputId && value.poll_message;
    //             });
    //
    //             // Sanitize the inputs returned (no breaks, incomplete inputs, or buttons) and add comparison inputs
    //             this.compares = response.result.filter(function (value) {
    //                 return value.name && !value.name.includes('button') && value.label;
    //             });
    //
    //             // Add target groups
    //             this._getTargetGroupInputs(response.result);
    //         }
    //     });
    // }

    private _getTargetGroupInputs(inputs: any = [], pages: any = []): void {

        const radioInputs = new Array;

        // Initialize and reset the groups
        this.targetGroups = [];

        // Remove all but radio inputs
        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].name && inputs[key].name.includes('radio')) {
                radioInputs.push(inputs[key]);
            }
        }

        // Assemble the target object
        if (pages && pages.length > 0) {
            this.targetGroups.push({
                type: 'Pages',
                targets: pages
            });
        }

        if (radioInputs && radioInputs.length > 0) {
            this.targetGroups.push({
                type: 'Inputs',
                targets: radioInputs
            });
        }
    }

    private _isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    private _isStringJson(string: string) {
        try {
            JSON.parse(string);
            return true;
        } catch (e) {
            return false;
        }
    }
}
