import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public currentUserRole = 1;
    public permissions = {
        10: 'Administrator',
        5: 'Manager',
        1: 'Coach'
    };

    constructor() {
    }
}
