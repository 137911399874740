import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar, MatSort} from '@angular/material';
import {ApiService} from '../../services/api.service';
import {UsersService} from '../../services/users.service';
import {HelpersService} from '../../services/helpers.service';
import {ResponseInterface} from '../../interfaces/response.interface';
// import {UsersCreateComponent} from './users-create.component';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';
import {UsersCreateComponent} from '../users/users-create.component';
import {ActivatedRoute, ParamMap} from '@angular/router';

@Component({
    selector: 'app-admins',
    templateUrl: './admins.component.html',
    styleUrls: ['./admins.component.sass']
})
export class AdminsComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    public adminTableColumns: string[] = ['name', 'email', 'role', 'last_login_at', 'edit'];
    public adminTable;
    public adminCount: number;

    constructor(
        private _api: ApiService,
        private _users: UsersService,
        private _dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getAdmins(this._api.accountId);
    }

    create() {
        const dialogRef = this._dialog.open(AdminsCreateComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getAdmins(this._api.accountId);
            }
        });
    }

    delete(unique_id: string, name: string) {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: name }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._api.deleteAdmin(unique_id).subscribe((response: ResponseInterface) => {
                    if (response.success) {
                        this.getAdmins(this._api.accountId);
                    }
                });
            }
        });
    }

    getAdmins(accountId: string) {
        this._api.getAdminsByAccount(accountId).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.adminTable = response.result.map(admin => {
                    admin.name = admin.first_name + ' ' + admin.last_name;
                    admin.role = this._users.permissions[admin.permission];
                    return admin;
                });
                this.adminCount = response.result.length;
                this.adminTable.sort = this.sort;
            }
        });
    }

}

@Component({
    selector: 'app-admins-create',
    template: `
        <h2 mat-dialog-title>Create Admin</h2>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
                <input matInput type="text" name="first_name" [(ngModel)]="firstName" placeholder="First Name">
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input matInput type="text" name="last_name" [(ngModel)]="lastName" placeholder="Last Name">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
                <input matInput (input)="phoneNumber = helper.formatPhoneNumber(phone.value)" #phone type="tel" name="phone_number"
                       [value]="phoneNumber" maxlength="14" [(ngModel)]="phoneNumber" placeholder="Phone Number">
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input matInput type="email" name="email" [(ngModel)]="email" placeholder="Email">
            </mat-form-field>
        </div>

        <h3 mat-dialog-title>Set Password</h3>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
                <input matInput type="password" name="password" [(ngModel)]="password" placeholder="Password">
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input matInput type="password" name="password_confirmation" [(ngModel)]="passwordConfirmation"
                       placeholder="Password Confirmation">
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" (click)="create()">Create</button>
    `,
    styleUrls: ['./admins.component.sass']
})
export class AdminsCreateComponent {

    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public email: string;
    public password: string;
    public passwordConfirmation: string;

    constructor(
        public helper: HelpersService,
        private _api: ApiService,
        private _dialog: MatDialogRef<UsersCreateComponent>,
        private _snackBar: MatSnackBar
    ) {
    }

    create() {
        const data = {
            account_id: null,
            first_name: this.firstName,
            last_name: this.lastName,
            phone_number: this.phoneNumber,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            permission: 1
        };

        if (this.password === this.passwordConfirmation) {
            this._api.createAdmin(data).subscribe((response: ResponseInterface) => { console.log(response);
                if (response.success) {
                    this._snackBar.open(this.firstName + ' was successfully added.', '', {
                        duration: 3500
                    });
                    this._dialog.close(true);
                }
            });
        } else {
            this._snackBar.open('Passwords do not match.', '', {
                duration: 3500
            });
        }
    }
}
