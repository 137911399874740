import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {CategoryInterface} from '../../interfaces/category.interface';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import * as moment from 'moment';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ConfirmCreateComponent} from '../../components/confirm-create/confirm-create.component';
import {ContentType} from '../../enums/content-type';
import {CreateCategoryComponent} from '../../components/create-category/create-category.component';

@Component({
    selector: 'app-learning-edit',
    templateUrl: './learning-edit.component.html',
    styleUrls: ['./learning-edit.component.sass']
})
export class LearningEditComponent implements OnInit {

    public categories: CategoryInterface[];
    public title: string;
    public selectedCategories: number[] = [];
    public publish_at: any;
    public content: string;
    public url = null;
    public shareUrl = null;
    public learningID: number;
    public mediaFileDisplay: string;
    public content_type: string;
    public options: Object = {
        key: 'dB8C6D5E2A-9H3E2I2B2C6B3B2B5B1F1phbndxD-11A-9B1vyz==',
        required: true
    };
    public contentTypes = [
        {
            name: 'News',
            slug: ContentType.News,
        },
        {
            name: 'Share',
            slug: ContentType.Share,
        },
        {
            name: 'Learning',
            slug: ContentType.Learning,
        },
    ];

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackbar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this._route.paramMap.subscribe((result: ParamMap) => {
            const id = result.get('id');

            if (id !== 'create') {
                this.learningID = parseInt(id, 10);
                this.getPost();
            }
        });

        this._api.getLearningCategories().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.categories = response.result;
            }
        });
    }

    getPost() {
        this._api.getPost(this.learningID).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.title = response.result.title;
                this.content_type = response.result.content_type;
                this.content = response.result.content;
                this.selectedCategories = response.result.categories.map(category => {
                    return category.category_id;
                });
                this.publish_at = moment(response.result.publish_at);
                this.url = response.result.media_url;

                if (this.url) {
                    const mediaDisplayParts = response.result.media_url.split('/');
                    this.mediaFileDisplay = mediaDisplayParts[mediaDisplayParts.length - 1];
                }
            }
        });
    }

    save() {
        const data = {
            categories: this.selectedCategories,
            content_type: this.content_type,
            title: this.title,
            content: this.content,
            url: this.url,
            share_url: this.shareUrl,
            publish_at: this._getDateWithTime(this.publish_at.format('YYYY-MM-DD'))
        };

        const input = new FormData();

        if (this.selectedCategories.length) {
            for (let i = 0; i < this.selectedCategories.length; i++) {
                input.append('categories[]', this.selectedCategories[i].toString());
            }
        }

        const properties = ['content_type', 'title', 'content', 'url', 'publish_at'];

        for (let i = 0; i < properties.length; i++) {
            if (this[properties[i]]) {
                if (properties[i] === 'publish_at') {
                    input.append(properties[i], this._getDateWithTime(this[properties[i]].format('YYYY-MM-DD')));
                } else {
                    input.append(properties[i], this[properties[i]]);
                }
            }
        }

        if (this.learningID) {
            this.update(input);
        } else {
            const dialogRef = this._dialog.open(ConfirmCreateComponent, {
                width: '500px',
                data: 'learning post'
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.create(input);
                }
            });
        }

    }

    update(data) {
        this._api.updateLearning(this.learningID, data).subscribe((response: ResponseInterface) => {

            if (response.success) {
                this._router.navigateByUrl('/learning');
            } else {
                this._snackbar.open(response.message, '', {
                    duration: 3000,
                });
            }
        });
    }

    create(data) {
        this._api.createLearning(data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._router.navigateByUrl('/learning');
            } else {
                this._snackbar.open(response.message, '', {
                    duration: 3000,
                });
            }
        });
    }

    createCategory() {
        const dialogRef = this._dialog.open(CreateCategoryComponent, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                // Add the new category if provided
                this.categories.push({
                    id: result.id,
                    title: result.title
                });
            }
        });
    }

    mediaFileChange(event) {
        if (event.target.files.length) {
            this.url = event.target.files[0];
            this.mediaFileDisplay = event.target.files[0].name;
        }
    }

    deleteMedia() {
        this.url = null;
        this.mediaFileDisplay = null;
    }

    private _getDateWithTime(date) {
        const _ = moment();
        return date + ' ' + _.hour() + ':' + _.minute() + ':' + _.second();
    }

}
