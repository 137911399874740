import {Component, Inject, OnInit} from '@angular/core';
import {ResponseInterface} from '../../interfaces/response.interface';
import {ApiService} from '../../services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-assignment-duplicate',
    template: `
        <h2 mat-dialog-title>Duplicate {{duplicateTitle}}</h2>
        <p>Would you like to duplicate {{duplicateTitle}}?</p>
        <mat-form-field>
            <input matInput type="text" name="title" placeholder="New Title" [(ngModel)]="title">
        </mat-form-field>
        <button mat-raised-button (click)="duplicate()" color="primary">Duplicate</button>
    `,
    styles: [`
        .mat-form-field {
            display: block
        }
    `]
})
export class AssignmentDuplicateComponent implements OnInit {

    public title: string;
    public duplicateTitle: string;
    public duplicateId: number;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<AssignmentDuplicateComponent>,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.duplicateTitle = this.data.duplicateTitle;
        this.duplicateId = this.data.duplicateId;
    }

    duplicate() {
        this._api.duplicateAssignment(this.duplicateId, {
            title: this.title
        }).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._snackBar.open(`${this.duplicateTitle} successfully duplicated`, '', {
                    duration: 3500
                });
                this._dialog.close();
            }
        });
    }

}
