import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
    activeUsers = 0;
    activeCourses = 0;
    totalUsers = 0;
    totalCourses = 0;
    name: string;

    constructor(
        private _api: ApiService
    ) {
    }

    ngOnInit() {
        this._api.getActiveUsers().subscribe((response: ResponseInterface) => {
            this.activeUsers = response.result.active;
            this.totalUsers = response.result.total;
        });

        this._api.getActiveCourses().subscribe((response: ResponseInterface) => {
            this.activeCourses = response.result.active;
            this.totalCourses = response.result.total;
        });

        this.name = localStorage.getItem('name');
    }

}
