import {Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef, MatSnackBar,
    MatTabChangeEvent,
} from '@angular/material';
import * as shape from 'd3-shape';

import {AssignmentsService} from '../../services/assignments.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {OnboardingService} from '../../services/onboarding.service';
import {OnboardingEditDialogComponent} from './onboarding-edit-dialog.component';
import {OnboardingEditComponent} from './onboarding-edit.component';
import {HttpEvent} from '@angular/common/http';

@Component({
    selector: 'app-onboarding',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.sass']
})
export class OnboardingComponent implements OnInit, OnDestroy {

    public view: any[];
    public active = false;
    public hierarchialGraph = {
        nodes: [],
        links: []
    };
    public createNodeId;
    public autoZoom = true;
    public panOnZoom = true;
    public enableZoom = true;
    public autoCenter = true;
    public curve = shape.curveBundle.beta(1);
    public orientation = 'TB';
    public colorScheme = 'aqua';
    public assignments = [];
    public isLoading = true;
    public currentNode$ = new Subject();
    public collapsed = false;

    @ViewChild('graph') public graph;

    private _activeAssignment: number;
    private _onboardingSubscription: Subscription;
    private _onboardingAddSubscription: Subscription;
    private _assignmentsSubscription: Subscription;

    constructor(
        public assignmentService: AssignmentsService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _api: ApiService,
        private _dialog: MatDialog,
        private _onboarding: OnboardingService
    ) {
    }

    ngOnInit() {

        // If the assignments have already been retrieved, get the workflow from that data
        if (this.assignmentService.assignments.length) {
            this._activeAssignment = this.assignmentService.assignments[0].id;
            this.assignmentService.activeAssignment = this.assignmentService.assignments[0];
            this.getWorkflow(this._activeAssignment);
        }

        // Monitor the assignment service for changes
        this._assignmentsSubscription = this.assignmentService.assignment$.subscribe(result => {
            this._activeAssignment = result[0].id;
            this.assignmentService.activeAssignment = result[0];
            this.getWorkflow(result[0].id);
        });

        // Build the graph
        this._onboardingSubscription = this._onboarding.deselect$.subscribe(result => {
            if (result) {
                this.active = false;
                for (let i = 0; i < this.hierarchialGraph.nodes.length; i++) {
                    if (this.hierarchialGraph.nodes[i].id === result.toString()) {
                        const nodes = JSON.parse(JSON.stringify(this.hierarchialGraph.nodes));
                        nodes.splice(i, 1);
                        this.hierarchialGraph.nodes = nodes;

                        for (let x = 0; x < this.hierarchialGraph.links.length; x++) {
                            if (this.hierarchialGraph.links[x].source === result.toString()
                                || this.hierarchialGraph.links[x].target === result.toString()) {
                                const links = JSON.parse(JSON.stringify(this.hierarchialGraph.links));
                                links.splice(x, 1);
                                this.hierarchialGraph.links = links;
                            }
                        }
                    }
                }
            }
        });

        // Retrieve the assignment ID and update the workflow
        this._onboardingAddSubscription = this._onboarding.add$.subscribe((result: number) => {
            this.active = false;
            this.getWorkflow(result);
        });
    }

    ngOnDestroy() {
        this._onboardingSubscription.unsubscribe();
        this._onboardingAddSubscription.unsubscribe();
        this._assignmentsSubscription.unsubscribe();
    }

    getWorkflow(assignmentId: number = null) {

        // Fallback to the current assignment ID
        if (!assignmentId) {
            assignmentId = this._activeAssignment;
        }

        // TODO: remove links if cannot be found (throws error if link goes nowhere)
        this._api.getOnboardingFlow(assignmentId).subscribe((response: ResponseInterface) => {

            // Iterate through all nodes
            for (let n = 0; n < response.result.nodes.length; n++) {
                response.result.nodes[n].id = response.result.nodes[n].real_id.toString();
            }

            // Iterate through all links
            for (let i = 0; i < response.result.links.length; i++) {
                response.result.links[i].source = response.result.links[i].source.toString();
                response.result.links[i].target = response.result.links[i].target.toString();
            }

            this.hierarchialGraph.nodes = response.result.nodes;
            this.hierarchialGraph.links = response.result.links;
            this.isLoading = false;
        });
    }

    createNode(): void {

        // Open the new node dialog, when closed them open the actual select node
        const dialogRef = this._dialog.open(OnboardingCreateNodeComponent, {
            data: {
                assignmentId: this._activeAssignment
            },
            width: '450px'
        });

        dialogRef.afterClosed().subscribe(result => {

            const id = this._onboarding.createNewNodeId;
            const title = this._onboarding.createNewNodeTitle;

            if (id) {
                const event = {
                    node: {
                        id: id,
                        nodeId: id,
                        title: title
                    },
                    real_id: id
                };
                this.selectNode(event);
            }
        });

    }

    selectNode(event): void {

        this.collapsed = false;
        event.assignmentID = this._activeAssignment;

        const nodes = this.graph.graph._nodes;

        for (const prop in nodes) {
            if (nodes.hasOwnProperty(prop) && typeof nodes[prop] !== 'undefined') {
                nodes[prop].options.strokeWidth = 0;
            }
        }

        if (event.options) {
            event.options.strokeWidth = 2;
        }

        if (event instanceof MouseEvent) {
            event = {
                create: true,
                assignmentID: this._activeAssignment
            };
        }

        this._onboarding.select(event);

        this.setToActive();

        // Open the content editor
        event.node.nodeId = event.real_id;
        event.node.link = this.buildLink(event.assignmentID, event.node.nodeId, event.node.waypoint);


        this.openNode(event);
    }

    openNode(event) {

        // If an empty event is sent, set the defaults
        if (!event.node.showTitleField) {
            event.node.showTitleField = true;
        }

        // Add the obligatory events
        event.node.assignmentID = this._activeAssignment;
        event.node.hierarchialGraph = this.hierarchialGraph;

        const dialogRef = this.dialog.open(OnboardingEditComponent, {
            data: event.node,
            width: '90vw'
        });

        dialogRef.afterClosed().subscribe(result => {

            this.getWorkflow();

            if (result && result.save) {
                this.snackBar.open('Page Saved', null, {
                    duration: 3000
                });
            }
        });
    }

    deleteAssignment() {
        this.assignmentService.delete();
        this.active = false;
    }


    openLink() {
        const data = {
            assignmentId: this._activeAssignment
        };
        this._dialog.open(OnboardingLinkDialogComponent, {
            data,
            width: '450px'
        });
    }

    buildLink(assignmentId: number, nodeId: any, waypoint: any = null) {
        nodeId = waypoint !== 'start' ? nodeId : waypoint;
        const domain = window.location.href.indexOf('admin.elation.com') > -1 ? 'https://mycoachmalcom.com' : window.location.href.indexOf('admin.elation.dev') > -1 ? 'https://malcolm.elation.dev' : 'http://localhost:4201';
        return `${domain}/onboarding/${this._api.accountId}/${assignmentId}/${nodeId}`;
    }

    getCurrentNode(): Observable<any> {
        return this._onboarding.nodes$;
    }

    changeAssignment(change: MatTabChangeEvent) {
        this.collapsed = false;
        this.isLoading = true;
        this._activeAssignment = this.assignmentService.assignments[change.index].id;
        this.assignmentService.currentAssignment = this._activeAssignment;
        this.assignmentService.activeAssignment = this.assignmentService.assignments[change.index];
        this.getWorkflow(this._activeAssignment);
    }

    setToActive() {
        this.active = true;
    }

    setToInactive() {
        this.active = false;
    }
}

@Component({
    selector: 'app-onboarding-create-node',
    template: `
        <h3>Create Page</h3>
        <div>
            <mat-form-field>
                <input matInput
                       required
                       placeholder="Add a Title"
                       class="field-title"
                       name="title"
                       [(ngModel)]="title"/>
            </mat-form-field>
        </div>
        <div>
            <button type="button" (click)="createNodeAndClose()" mat-raised-button
                    color="primary">
                Create New Page
            </button>
        </div>
    `,
    styles: [`
        :host {
            text-align: left;
        }
        mat-form-field {
            width: 100%;
        }
    `]
})
export class OnboardingCreateNodeComponent implements OnInit {

    public title: string;

    constructor(
        private _api: ApiService,
        private _onboarding: OnboardingService,
        public dialogRef: MatDialogRef<OnboardingCreateNodeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    createNodeAndClose() {

        const $this = this;

        this.createNode(function (e): void {

            if (e.success) {
                $this.dialogRef.close();
            }
        });
    }

    createNode(callback = null): void {

        const input = new FormData();
        input.append('assignment_id', this.data.assignmentId);
        input.append('title', this.title);

        this._api.createOnboarding(input).subscribe((event: HttpEvent<any>) => {

            // Assign the new node ID
            this._onboarding.createNewNodeId = event['result'].id;
            this._onboarding.createNewNodeTitle = this.title;

            // If successful, run callback
            if (callback) {
                callback(event);
            }

        }, error => {
            // this.snackBar.open(error, null, {
            //     duration: 3000
            // });
        });
    }
}

@Component({
    selector: 'app-onboarding-link-dialog',
    template: `
        <h3>Your Onboarding Link</h3>
        <p><a href="{{link}}" target="_blank">Click to open the workflow</a></p>
        <p>
            <small>Make sure that a node waypoint is designated as 'start'.</small>
        </p>
    `,
    styles: [`
        :host {
            text-align: center;
        }
        a {
            outline: none;
        }
    `]
})
export class OnboardingLinkDialogComponent implements OnInit {

    public data: any;
    public domain = window.location.href.indexOf('admin.elation.com') > -1 ? 'https://mycoachmalcom.com' : window.location.href.indexOf('admin.elation.dev') > -1 ? 'https://malcolm.elation.dev' : 'http://localhost:4201';
    public link = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialog: any,
        public dialogRef: MatDialogRef<OnboardingLinkDialogComponent>,
        private _api: ApiService
    ) {
        this.data = dialog;
    }

    ngOnInit() {
        this.buildLink(this.data.assignmentId);
    }

    buildLink(assignmentId: number): void {
        this.link = `${this.domain}/onboarding/${this._api.accountId}/${assignmentId}/start`;
    }
}
