import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ResponseInterface} from '../../interfaces/response.interface';
import * as moment from 'moment';
import {MatDialog, MatSelectChange, MatSnackBar} from '@angular/material';
import {UsersEditMetaComponent} from './users-edit-meta.component';
import {UserImageEditComponent} from './user-image-edit.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {NotificationsComponent} from '../notifications/notifications.component';

export interface InteractionList {
    name: string;
    category: any;
    interaction: any;
    dateTime: string;
}

@Component({
    selector: 'app-edit',
    templateUrl: './users-edit.component.html',
    styleUrls: ['./users-edit.component.sass']
})
export class UsersEditComponent implements OnInit {

    public response;
    public interactionsColumns: string[] = ['name', 'category', 'interaction', 'dateTime'];
    public interactions;
    public interactionCount: number;
    public user = {
        unique_id: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        last_login_at: '',
        interactions: [],
        profile_image_url: '',
        points: {
            assignment_count: [],
            count: 0
        }
    };
    public userAssignments = [];
    public selectedAssignment = [];
    public selectedCoach: any = false;
    public assignmentsData = {};
    public uniqueId: string;
    public assignmentString = '';
    public assignments = [];
    public coaches = [];

    // Interaction pagination
    public page = 1;

    private _userImage: any;

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _cd: ChangeDetectorRef,
        private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this._route.paramMap.subscribe((params: ParamMap) => {
            this.uniqueId = params.get('id');

            // Get the user and their list of assignments
            this.getUser();
            this._getUserAssignments();

            // Get all available coaches
            this._getAllCoaches();

        });
    }

    getUser() {
        this._api.getUser(this.uniqueId).subscribe((response: ResponseInterface) => {

            this.response = response;

            // If user does not exist, the redirect to user list
            if (!response.success) {
                this._router.navigate(['/users']);
            }

            // If the result is empty, show the response message
            if (!response.result || response.result.length <= 0) {
                this._snackBar.open(response.message, '', {
                    duration: 2000
                });
                return;
            }

            // Set the user META
            this.user = response.result;
            this.user.last_login_at = moment(this.user.last_login_at).calendar();

            if (!this.user.profile_image_url) {
                this.user.profile_image_url = '//placehold.it/450x450';
            }

            // Create the interaction table
            const interactions = response.result.interactions;

            this.interactions = [];

            for (let i = 0; i < interactions.length; i++) {
                this.interactions.push({
                    name: (interactions[i].onboarding ? interactions[i].onboarding.title : 'N/A'),
                    category: (interactions[i].category ? interactions[i].category.title : null),
                    interaction: interactions[i].interaction,
                    dateTime: interactions[i].created_at
                });
            }

            this.interactionCount = this.interactions.length;

            // Get the coaches assigned to the user
            this._getAssignedCoaches();
        });
    }

    editMetaDialog(key, value, placeholder, type: string = 'text') {

        const dialogRef = this._dialog.open(UsersEditMetaComponent, {
            width: '600px',
            data: {
                uniqueId: this.uniqueId,
                key,
                value,
                placeholder,
                type: type
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.user[key] = result;
            }
        });
    }

    passwordReset() {
        this._dialog.open(PasswordResetComponent, {
            width: '600px',
            data: {
                id: this.user.unique_id
            }
        });
    }

    assignmentChange(change: MatSelectChange) {

        const assignments = [];

        /*for (let i = 0; i < change.value.length; i++) {
            assignments = assignments.concat(this.assignmentsData[change.value[i]]);
        }*/

        this.selectedAssignment = change.value;

        // this.interactions = assignments;
        // this.interactions = [];
        // this.interactionCount = assignments.length;
    }

    assignmentToggle(opened: boolean) {

        if (opened) {
            return;
        }

        const data = {
            assignments: this.selectedAssignment
        };

        this._api.addUserAssignment(this.uniqueId, data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._getUserAssignments();
            }
        });
    }

    assignUserToAdmin(event: any) {

        const user_id = this.user.unique_id;
        const data = {
            admin_id: event.value
        };
        this._api.createUserOnAdmin(user_id, data).subscribe((response: ResponseInterface) => {
            console.log(response);
        });
    }

    userImageChange(event) {
        if (event.target.files.length) {
            this._userImage = event.target.files[0];
        }
    }

    editUserImage() {

        const dialogRef = this._dialog.open(UserImageEditComponent, {
            width: '600px',
            data: this.uniqueId
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getUser();
            }
        });
    }

    sendNotification(): void {
        const dialog = this._dialog.open(NotificationsComponent, {
            width: '775px',
            data: {
                id: this.user.unique_id,
            }
        });
    }

    private _getUserAssignments() {
        this._api.userAssignments(this.uniqueId).subscribe((response: ResponseInterface) => {
            this.userAssignments = response.result;

            for (let i = 0; i < this.userAssignments.length; i++) {
                this.selectedAssignment.push(this.userAssignments[i].assignment_id);
            }

            this._api.getAllAssignments().subscribe((res: ResponseInterface) => {
                if (res.success) {
                    this.assignments = res.result;
                }
            });

            this._assignmentString();
        });
    }

    private _assignmentString() {
        const assignmentNames = [];

        for (let i = 0; i < this.userAssignments.length; i++) {
            assignmentNames.push(this.userAssignments[i].assignment.title);
        }

        this.assignmentString = assignmentNames.join(', ');
    }

    private _getAllCoaches() {
        this._api.getAdmins().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.coaches = response.result;
                for (const key in this.coaches) {
                    if (this.coaches.hasOwnProperty(key)) {
                        this.coaches[key].full_name = this.coaches[key].first_name + ' ' + this.coaches[key].last_name;
                    }
                }
            }
        });
    }

    private _getAssignedCoaches() {
        this._api.getCoachesByUser(this.user.unique_id).subscribe((response: ResponseInterface) => {
            this.selectedCoach = [];
            for (let i = 0; i < response.result.length; i++) {
                const admin_id = response.result[i].admin_id;
                this.selectedCoach.push(admin_id);
            }
        });
    }
}
