import {Subject} from 'rxjs';

export class LoginService {

    private _loginSource = new Subject();

    public login$ = this._loginSource.asObservable();

    login(data: {
        id: string,
        account_id: string,
        first_name: string,
        last_name: string
    }): void {
        this._loginSource.next(data);
    }
}
