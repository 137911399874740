import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {UsersService} from '../../services/users.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
    email: string;
    password: string;
    page: string;

    constructor(
        private _api: ApiService,
        private _router: Router,
        private _activeRoute: ActivatedRoute,
        private _loginService: LoginService,
        private _users: UsersService,
        private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        // Stand-in name
        localStorage.setItem('name', 'guest');

        // Update page name by route
        this._activeRoute.params.subscribe(params => {
                this.page = params['string'];
            }
        );
    }

    login() {
        const overlay = document.getElementById('loginOverlay');
        overlay.classList.remove('hide');

        this._api.adminLogin(this.email, this.password).subscribe((response: ResponseInterface) => {
            if (response.success) {
                localStorage.setItem('access_token', response.result.access_token);
                localStorage.setItem('refresh_token', response.result.refresh_token);
                localStorage.setItem('token_type', response.result.token_type);
                localStorage.setItem('id', response.result.user.unique_id);
                localStorage.setItem('name', response.result.user.first_name);
                localStorage.setItem('account_id', response.result.account.account_id);
                this._users.currentUserRole = response.result.account.role;

                this._loginService.login({
                    id: response.result.user.unique_id,
                    account_id: response.result.account.account_id,
                    first_name: response.result.user.first_name,
                    last_name: response.result.user.last_name
                });

                this._router.navigateByUrl('/dashboard');
            } else {
                this.showSnackMessage('Unable to log in.');
            }
            overlay.classList.add('hide');
        });
    }

    create() {
        // This must be done manually.
    }

    forgot() {
        this.showSnackMessage('Password reset email sent');
    }

    showSnackMessage(message: string) {
        this._snackBar.openFromComponent(LoginErrorComponent, {
            duration: 3000,
            data: {
                message: message
            }
        });
    }

}

@Component({
    selector: 'app-login-error',
    template: `
        {{message}}
    `,
    styles: [`
    `],
})
export class LoginErrorComponent {

    public message: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
        this.message = data.message;
    }
}
