import {Subject} from 'rxjs';

export class InputsService {
    private inputsSource = new Subject();

    inputs$ = this.inputsSource.asObservable();

    add(data: {
        type: string,
        label: string,
        limit: number,
        attributes: { color: string, iconPath: string, label: string, name: string, target: string, value: string }[],
        options: { label: string, name: string, target: string, value: string, points: number }[],
        textarea: string,
        value: string,
        format: string,
        compared: string,
        compared_assignment_id: number,
        prefix: string,
        postfix: string,
        points: number
    }) {
        this.inputsSource.next(data);
    }

    update(data: {
        index: number,
        type: string,
        label: string,
        limit: number,
        attributes: { color: string, iconPath: string, label: string, name: string, target: string, value: string }[],
        options: { label: string, name: string, target: string, value: string, points: number }[],
        textarea: string,
        value: string,
        format: string,
        compared: string,
        compared_assignment_id: number,
        prefix: string,
        postfix: string,
        points: number
    }) {
        this.inputsSource.next(data);
    }
}
