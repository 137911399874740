import {Injectable} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Subject} from 'rxjs';
import {ApiService} from './api.service';

import {AssignmentCreateComponent} from '../pages/assignments/assignment-create.component';
import {AssignmentDuplicateComponent} from '../pages/assignments/assignment-duplicate.component';
import {ConfirmDeleteComponent} from '../components/confirm-delete/confirm-delete.component';
import {ResponseInterface} from '../interfaces/response.interface';

@Injectable()
export class AssignmentsService {

    private _assignmentsSource = new Subject();

    public currentAssignment = 0;
    public activeAssignment: any;
    public assignments = [];
    public assignment$ = this._assignmentsSource.asObservable();

    constructor(
        private _api: ApiService,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar
    ) {
        this.init();
    }

    init() {
        this._api.getAllAssignments().subscribe((res: ResponseInterface) => {

            if (res.success) {

                // If no assignments have been created, then forcethe user to create one
                if (res.result.length > 0) {
                    this.assignments = res.result;

                    if (this.assignments.length) {
                        this.currentAssignment = this.assignments[0].id;
                        this.activeAssignment = this.assignments[0];
                    }

                    this._assignmentsSource.next(this.assignments);
                } else {
                    this.create('This account currently has no available assignments.  Please create your default assignment.');
                }
            }
        });
    }

    create(message: string = null): void {
        const dialogRef = this._dialog.open(AssignmentCreateComponent, {
            width: '600px',
            data: {
                message: message
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this._refreshAssignments();
        });
    }

    duplicate(): void {
        const dialogRef = this._dialog.open(AssignmentDuplicateComponent, {
            width: '600px',
            data: {
                duplicateId: this.activeAssignment.id,
                duplicateTitle: this.activeAssignment.title
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this._refreshAssignments();
        });
    }

    delete() {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: {
                id: this.activeAssignment.id,
                title: this.activeAssignment.title
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._api.deleteAssignment(this.currentAssignment).subscribe((res: ResponseInterface) => {
                    if (res.success) {
                        this._snackBar.open(`${this.activeAssignment.title} successfully deleted`, '', {
                            duration: 3500
                        });
                        this._refreshAssignments();
                    }
                });
            }
        });
    }

    private _refreshAssignments() {
        this._api.getAllAssignments().subscribe((res: ResponseInterface) => {
            if (res.success) {
                this.assignments = res.result;
            }
        });
    }
}
