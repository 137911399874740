import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-confirm-create',
    template: `
        <h2 mat-dialog-title>Confirm Create</h2>
        <div class="mat-dialog-content">Are you sure you wish to create the {{ data }}?</div>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="confirm()">Yes</button>
            <button mat-button (click)="deny()">No</button>
        </mat-dialog-actions>
    `,
    styleUrls: ['./confirm-create.component.sass']
})
export class ConfirmCreateComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _dialog: MatDialogRef<ConfirmCreateComponent>) {
    }

    ngOnInit() {
    }

    confirm() {
        this._dialog.close(true);
    }

    deny() {
        this._dialog.close(false);
    }

}
