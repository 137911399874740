import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {

    constructor() {
    }

    formatPhoneNumber(string: string) {
        const cleaned = ('' + string).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            console.log('(' + match[1] + ') ' + match[2] + '-' + match[3]);
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    sortAnObject(object, variable) {
        return object.sort(function (a, b) {
            if (a[variable] < b[variable]) {
                return -1;
            }
            if (a[variable] > b[variable]) {
                return 1;
            }
            return 0;
        });
    }
}
