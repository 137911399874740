import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef, MatSnackBar} from '@angular/material';
import {ResponseInterface} from '../../interfaces/response.interface';

@Component({
    selector: 'app-insight-create',
    templateUrl: './insight-create.component.html',
    styleUrls: ['./insight-create.component.sass']
})
export class InsightCreateComponent implements OnInit {

    public title: string;
    public description: string;
    public content: string;
    public iconset: string;
    public color: string;
    public order: number;
    public is_default = false;
    public isEditing = false;
    public options: Object = {
        key: 'dB8C6D5E2A-9H3E2I2B2C6B3B2B5B1F1phbndxD-11A-9B1vyz=='
    };

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<InsightCreateComponent>,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        if (this.data.title) {
            this.title = this.data.title;
            this.description = this.data.description;
            this.content = this.data.content;
            this.iconset = this.data.iconset;
            this.color = this.data.color;
            this.order = this.data.order;
            this.is_default = this.data.is_default;
            this.isEditing = true;
        }
    }

    create() {
        const data = {
            assignment_id: this.data.assignmentID,
            title: this.title,
            description: this.description,
            content: this.content,
            iconset: this.iconset,
            color: this.color,
            order: this.order,
            is_default: this.is_default
        };

        if (this.data.insightID) {
            this.createChild(data);

            return;
        }

        this._api.createInsight(data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            }
        });
    }

    createChild(data) {
        this._api.createChildInsight(this.data.insightID, data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            } else {
                this._snackBar.open(response.message, '', {
                    duration: 2000
                });
            }
        });
    }

    changeDefault(change: MatCheckboxChange) {
        this.is_default = change.checked;
    }

    edit() {
        const data = {
            title: this.title,
            description: this.description,
            content: this.content,
            iconset: this.iconset,
            color: this.color,
            order: this.order
        };

        if (this.data.parent_id) {
            this.editChild(data);

            return;
        }

        this._api.updateInsight(this.data.id, data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            }
        }, error => {
            console.log(error);
        });
    }

    editChild(data) {
        this._api.updateChildInsight(this.data.parent_id, this.data.id, data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            }
        }, error => {
            console.log(error);
        });
    }

}
