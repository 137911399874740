import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {AccountImageEditComponent} from '../accounts/account-image-edit.component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-accounts-edit',
    templateUrl: './accounts-edit.component.html',
    styleUrls: ['./accounts-edit.component.sass']
})
export class AccountsEditComponent implements OnInit {

    public response;
    public account = {
        uid: '',
        parent_id: null,
        title: null,
        logo_url: null
    };

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this._route.paramMap.subscribe((params: ParamMap) => {
            this.account.uid = params.get('id');
            this._getAccount();
        });
    }

    editAccountLogo() {
        const dialogRef = this._dialog.open(AccountImageEditComponent, {
            width: '600px',
            data: this.account
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._getAccount();
            }
        });
    }

    private _getAccount() {

        this._api.getAccount(this.account.uid).subscribe((response: any) => {
            // If admin does not exist, the redirect to admin list
            if (response.success) {
                this.account = response.result;
            } else {
                this._router.navigate(['/admins']);
            }
        });
    }

}
