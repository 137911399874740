import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function requiredIf(compareControl: AbstractControl, callback: Function = null) {
    return function (control: AbstractControl): ValidationErrors | null {
        if (callback) {
            return callback(control, compareControl);
        }

        return null;
    };
}
