import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Validation} from '../../services/validation';
import {NotificationType} from '../../enums/notification-type';
import {requiredIf} from '../../validators/required-if';
import {ApiService} from '../../services/api.service';
import * as moment from 'moment';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit {
    form: FormGroup;
    validation: Validation;
    messageNotification: string = NotificationType.MESSAGE;
    insightNotification: string = NotificationType.INSIGHT;
    contentNotification: string = NotificationType.CONTENT;
    insights = [];
    subInsights = [];
    categories = [];
    posts = [];
    isGroup = false;
    publishedAt: string = null;
    edit = false;
    public options: Object = {
        key: 'dB8C6D5E2A-9H3E2I2B2C6B3B2B5B1F1phbndxD-11A-9B1vyz=='
    };

    constructor(
        private api: ApiService,
        private dialog: MatDialogRef<NotificationsComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
    }

    ngOnInit() {
        // TODO: get the notification to edit it
        if (this.data.group) {
            this.isGroup = true;
        }

        this.api.getAllInsights().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.insights = response.result;
            }
        });

        this.api.getAllPosts().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.posts = response.result;
            }
        });

        const type = new FormControl(NotificationType.MESSAGE, [
            Validators.required,
        ]);

        const learning_id = new FormControl(null, [
            requiredIf(type, (control: AbstractControl, compareControl: AbstractControl) => {
                if (compareControl.value === NotificationType.CONTENT && !control.value) {
                    return {requiredIf: true};
                }

                return null;
            }),
        ]);

        this.form = new FormGroup({
            type,
            audience: new FormControl(),
            message: new FormControl(),
            published_at: new FormControl(null, [
                Validators.required,
            ]),
            insight_id: new FormControl(null, [
                requiredIf(type, (control: AbstractControl, compareControl: AbstractControl) => {
                    if (compareControl.value === NotificationType.INSIGHT && !control.value) {
                        return {requiredIf: true};
                    }

                    return null;
                }),
            ]),
            sub_insight: new FormControl(),
            learning_id,
            title: new FormControl(null, [
                requiredIf(learning_id, (control: AbstractControl, compareControl: AbstractControl) => {
                    if (type.value === NotificationType.CONTENT && compareControl.value === -1 && !control.value) {
                        return {requiredIf: true};
                    }

                    return null;
                }),
            ]),
            content: new FormControl(null, [
                requiredIf(learning_id, (control: AbstractControl, compareControl: AbstractControl) => {
                    if (type.value === NotificationType.CONTENT && compareControl.value === -1 && !control.value) {
                        return {requiredIf: true};
                    }

                    return null;
                }),
            ]),
            media: new FormControl(),
            category: new FormControl(),
        });

        this.validation = new Validation(this.form);

        if (this.data.notificationId) {
            this.getNotification();
        }
    }

    getNotification(): void {
        this.api.getNotification(this.data.notificationId).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.edit = true;
                this.form.controls['type'].setValue(response.result.type);

                if (response.result.groups.length) {
                    const groups = response.result.groups.map(group => group.name);
                    this.form.controls['audience'].setValue(groups);
                    this.isGroup = true;
                }

                this.form.controls['message'].setValue(response.result.message);
                const publishedAt = moment(response.result.published_at).format('YYYY-MM-DD HH:mm');
                this.form.controls['published_at'].setValue(publishedAt);
                this.publishedAt = publishedAt;

                if (response.result.insight_id) {
                    if (response.result.insight.parent_id) {
                        this.form.controls['insight_id'].setValue(response.result.insight.parent_id);
                        this.changeInsight();
                        this.form.controls['sub_insight'].setValue(response.result.insight.id);
                    } else {
                        this.form.controls['insight_id'].setValue(response.result.insight_id);
                    }
                }

                if (response.result.learning_id) {
                    this.form.controls['learning_id'].setValue(response.result.learning_id);
                }
            }
        });
    }

    changeInsight(): void {
        const insightId = this.form.controls['insight_id'].value;

        const selectedInsight = this.insights.find((insight: any) => {
            return insight.id === parseInt(insightId, 10);
        });

        this.subInsights = selectedInsight.children;
    }

    changeType(): void {
        const type = this.form.controls['type'].value;

        if (type === NotificationType.CONTENT) {
            this.api.getLearningCategories().subscribe((response: ResponseInterface) => {
                if (response.success) {
                    this.categories = response.result;
                }
            });
        }
    }

    schedule(): void {
        this.validation.validate();

        if (this.validation.getErrors().length) {
            return;
        }

        let data = {...this.form.value};
        data.published_at = moment(data.published_at).format('YYYY-MM-DD HH:mm');
        data.user_id = this.data.id;
        data = this.removeNullValues(data);

        if (data.type === NotificationType.CONTENT && data.learning_id === -1) {
            delete data.learning_id;
        }

        if (data.type === NotificationType.INSIGHT && data.sub_insight) {
            data.insight_id = data.sub_insight;
            delete data.sub_insight;
        }

        if (this.edit) {
            this.editNotification(data);
        } else {
            this.create(data);
        }
    }

    private create(data: any): void {
        this.api.createNotification(data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.dialog.close(true);
            }
        });
    }

    private editNotification(data: any): void {
        this.api.editNotification(this.data.notificationId, data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.dialog.close(true);
            }
        });
    }

    private removeNullValues(data: object): object {
        for (const item in data) {
            if (data.hasOwnProperty(item) && data[item] === null) {
                delete data[item];
            }
        }

        return data;
    }

}
