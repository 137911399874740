import {Component, OnInit} from '@angular/core';
import {AssignmentsService} from '../../services/assignments.service';

@Component({
    selector: 'app-create-assignment',
    template: `
        <span class="meta">
        <i (click)="assignments.create()" class="icon icon-create">Create Assignment</i>
        <i (click)="assignments.duplicate()" class="icon icon-duplicate">Duplicate Current Assignment</i>
        <i (click)="assignments.delete()" class="icon icon-delete">Delete Assignment</i>
    </span>
    `,
    styleUrls: ['./create-assignment.component.sass']
})
export class CreateAssignmentComponent implements OnInit {

    constructor(
        public assignments: AssignmentsService
    ) {
    }

    ngOnInit() {
    }

}
