import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort} from '@angular/material';
import {ApiService} from '../../services/api.service';
import {HelpersService} from '../../services/helpers.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {UsersCreateComponent} from './users-create.component';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';
import {NotificationsComponent} from '../notifications/notifications.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    public userTableColumns: string[] = ['name', 'email', 'points', 'assignments', 'last_login_at', 'edit'];
    public userTable;
    public userCount: number;

    constructor(
        public helper: HelpersService,
        private _api: ApiService,
        private _dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getUsers();
    }

    create() {
        const dialogRef = this._dialog.open(UsersCreateComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getUsers();
            }
        });
    }

    delete(unique_id: string, title: string) {
        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._api.deleteUser(unique_id).subscribe((response: ResponseInterface) => {
                    if (response.success) {
                        this.getUsers();
                    }
                });
            }
        });
    }

    getUsers() {

        let students: any = false;

        if (window.location.hash.includes('#students')) {
            const split = window.location.hash.split('-');
            students = split[1];
        }

        this._api.getUsers(students).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.userTable = response.result.map(user => {
                    user.name = user.first_name + ' ' + user.last_name;
                    if (user.assignments) {
                        user.assignments = user.assignments.map(userAssignment => {
                            return userAssignment.assignment.title;
                        });
                    }
                    user.edit = user.unique_id;

                    return user;
                });

                this.userCount = response.result.length;
                this.userTable.sort = this.sort;
            }
        });
    }

    notify(id: number): void {
        this._dialog.open(NotificationsComponent, {
            width: '775px',
            data: {
                id,
            }
        });
    }

}
