import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-onboarding-content',
    templateUrl: './onboarding-content.component.html',
    styleUrls: ['./onboarding-content.component.sass']
})
export class OnboardingContentComponent implements OnInit {
    public content: string;
    public options: Object = {
        key: 'dB8C6D5E2A-9H3E2I2B2C6B3B2B5B1F1phbndxD-11A-9B1vyz==',
        required: true
    };

    constructor(
        private _dialog: MatDialogRef<OnboardingContentComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
    }

    ngOnInit() {
        this.content = this._data.content;
    }

    save() {
        this._dialog.close(this.content);
    }

}
