import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import {ApiService} from '../../services/api.service';
import {UsersService} from '../../services/users.service';
import {KeysPipe} from '../../pipes/keys.pipe';
import {PasswordResetComponent} from '../users/password-reset/password-reset.component';

import * as moment from 'moment';
import {ResponseInterface} from '../../interfaces/response.interface';

@Component({
    selector: 'app-admins-edit',
    templateUrl: './admins-edit.component.html',
    styleUrls: ['./admins-edit.component.sass']
})
export class AdminsEditComponent implements OnInit {

    public response;
    public user = {
        unique_id: '',
        first_name: '',
        last_name: '',
        full_name: '',
        phone_number: '',
        email: '',
        permission: '',
        last_login_at: '',
        profile_image_url: '',
        biography: ''
    };

    private _userImage: any;

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public users: UsersService
    ) {
    }

    ngOnInit() {
        this._route.paramMap.subscribe((params: ParamMap) => {
            this.user.unique_id = params.get('id');
            this.getAdmin();
        });
    }

    getAdmin() {
        this._api.getAdmin(this.user.unique_id).subscribe((response: any) => {

            this.response = response;

            // If admin does not exist, the redirect to admin list
            if (!response.success) {
                this._router.navigate(['/admins']);
            }

            // If the result is empty, show the response message
            if (!response.result || response.result.length <= 0) {
                this._snackBar.open(response.message, '', {
                    duration: 2000
                });
                return;
            }

            this.user = response.result;
            this.user.full_name = this.user.first_name + ' ' + this.user.last_name;
            this.user.last_login_at = moment(this.user.last_login_at).calendar();

            if (!this.user.profile_image_url) {
                this.user.profile_image_url = '//placehold.it/450x450';
            }
        });
    }

    editUserImage() {
        const dialogRef = this._dialog.open(AdminsEditImageComponent, {
            width: '600px',
            data: this.user.unique_id
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getAdmin();
            }
        });
    }

    editMetaDialog(key, value, placeholder, type: string = 'text') {

        const dialogRef = this._dialog.open(AdminsEditMetaComponent, {
            width: '600px',
            data: {
                unique_id: this.user.unique_id,
                key,
                value,
                placeholder,
                type: type
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.user[key] = result;
            }
        });
    }

    editUserBiography(string: string) {

        if (this.user.biography !== string) {
            this._api.updateAdmin({unique_id: this.user.unique_id, biography: string.trim()})
                .subscribe((response: ResponseInterface) => {
                    if (response.success) {
                        this._snackBar.open('Biography Updated', '', {
                            duration: 2000
                        });
                        // this.user.permission = String(permission);
                        console.log(response);
                    }
                });
        }
    }

    editUserPermission(permission: number) {

        this._api.updateAdmin({unique_id: this.user.unique_id, permission: permission})
            .subscribe((response: ResponseInterface) => {
                if (response.success) {
                    this._snackBar.open('Permissions Updated', '', {
                        duration: 2000
                    });
                    // this.user.permission = String(permission);
                }
            });
    }

    passwordReset() {
        this._dialog.open(PasswordResetComponent, {
            width: '600px',
            data: {
                id: this.user.unique_id,
                is_admin: true
            }
        });
    }

    getPermissionLevelString(level) {
        return this.users.permissions[Number(level)];
    }

}

@Component({
    selector: 'app-admins-edit-meta',
    template: `
        <h2 mat-dialog-title>Edit {{data.placeholder}}</h2>
        <div>
            <mat-form-field fxFlex="100">
                <input
                        matInput
                        [type]="data.type"
                        [ngModel]="data.type === 'tel' ? (data.value | tel) : data.value"
                        [placeholder]="data.placeholder"
                        (ngModelChange)="data.value=$event"
                        [maxlength]="data.type === 'tel' ? 14 : 128">
            </mat-form-field>
        </div>
        <button mat-raised-button (click)="save()" color="accent">Save</button>
    `,
    styleUrls: ['./admins-edit.component.sass']
})
export class AdminsEditMetaComponent {

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<AdminsEditMetaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    save() {
        const data = {
            unique_id: this.data.unique_id
        };
        data[this.data.key] = this.data.value;
        this._api.updateAdmin(data).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(this.data.value);
            }
        });
    }
}

@Component({
    selector: 'app-admins-edit-image',
    template: `
        <h2 mat-dialog-title>Update Image</h2>
        <fieldset>
            <input (change)="userImageChange($event)" type="file" name="file" id="userImageUpload" class="hide">
            <label mat-raised-button for="userImageUpload" class="mat-button mat-raised-button mat-accent">Select Image</label>
            <span *ngIf="fileName">{{fileName}}</span>
        </fieldset><br>
        <button mat-raised-button (click)="update()" color="primary">Update</button>
    `,
    styleUrls: ['./admins-edit.component.sass']
})
export class AdminsEditImageComponent {

    public fileName: string;

    private image: any;

    constructor(
        private _api: ApiService,
        private _dialog: MatDialogRef<AdminsEditImageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    userImageChange(event) {
        if (event.target.files.length) {
            this.image = event.target.files[0];
            this.fileName = this.image.name;
        }
    }

    update() {
        const input = new FormData();
        console.log(this.data);
        input.append('unique_id', this.data);
        input.append('profile_image_url', this.image);

        this._api.updateAdmin(input).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this._dialog.close(true);
            }
        });
    }
}