import {Component, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {NotificationsComponent} from '../notifications/notifications.component';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import * as moment from 'moment';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';
import {NotificationType} from '../../enums/notification-type';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.sass']
})
export class NotificationListComponent implements OnInit {
    tableColumns = ['notification', 'type', 'audience', 'publish_date', 'status', 'edit'];
    notifications = [];
    page = 1;
    limit = 10;

    constructor(
        private dialog: MatDialog,
        private api: ApiService,
        private snack: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.getNotifications();
    }

    getNotifications(): void {
        this.api.getNotifications().subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.notifications = response.result;
            }
        });
    }

    create(): void {
        const dialog = this.dialog.open(NotificationsComponent, {
            width: '775px',
            data: {
                group: true,
            },
        });

        dialog.afterClosed().subscribe(result => {
            if (result === true) {
                this.getNotifications();
            }
        });
    }

    name(notification: any): string {
        if (notification.type === NotificationType.MESSAGE) {
            return notification.message;
        }

        if (notification.type === NotificationType.INSIGHT) {
            return notification.insight.title;
        }

        return notification.learning.title;
    }

    audience(notification: any): string {
        if (!notification.groups.length) {
            return `${notification.user.first_name} ${notification.user.last_name}`;
        }

        return notification.groups
            .flatMap(group => group.name.charAt(0).toUpperCase() + group.name.slice(1))
            .join(', ');
    }

    status(notification: any): string {
        const after = moment().isAfter(moment(notification.published_at));

        if (after) {
            return 'Sent';
        }

        return 'Scheduled';
    }

    publishDate(notification: any): string {
        return moment(notification.published_at).format('MM-DD-YYYY, h:mma');
    }

    edit(notificationIndex: number): void {
        this.dialog.open(NotificationsComponent, {
            width: '775px',
            data: {
                notificationId: this.notifications[notificationIndex].id,
            },
        });
    }

    confirmDelete(notificationIndex: number): void {
        const dialog = this.dialog.open(ConfirmDeleteComponent, {
            width: '450px',
            data: {
                title: 'notification',
            },
        });

        dialog.afterClosed().subscribe(result => {
            if (result === true) {
                this.delete(notificationIndex);
            }
        });
    }

    delete(notificationIndex: number): void {
        this.api.deleteNotification(this.notifications[notificationIndex].id).subscribe((response: ResponseInterface) => {
            if (response.success) {
                this.snack.open('Notification deleted successfully');
                this.notifications.splice(notificationIndex, 1);
                this.notifications = [...this.notifications];
            } else {
                this.snack.open(response.message);
            }
        });
    }

    getPage(event: any): void {
        console.log(event);
    }

}
