import {Component, OnInit, Inject} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-create-category',
    template: `
        <h2>Create a new category</h2>
        <mat-form-field>
            <input matInput [(ngModel)]="category" name="category" placeholder="Category Name">
        </mat-form-field><br>

        <button mat-raised-button color="accent" (click)="createNewCategory()">Create Category</button>
    `,
    styleUrls: ['./create-category.component.sass']
})
export class CreateCategoryComponent implements OnInit {

    public category;

    constructor(
        private _api: ApiService,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CreateCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
    }

    createNewCategory() {
        this._api.createCategory(this.category).subscribe((result: ResponseInterface) => {
            if (result.success) {
                this.dialogRef.close({
                    'id': result.result.id,
                    'title': this.category
                });
            } else {
                this._snackBar.open('There was a problem creating your category.  Please try again.', null, {
                    duration: 3000
                });
            }
        });
    }

}
