import {Observable, Subject} from 'rxjs';

export class OnboardingService {
    private onboardingSource = new Subject();
    private onboardingOffSource = new Subject();
    private onboardingAddSource = new Subject();

    public createNewNodeId;
    public createNewNodeTitle;

    nodes$ = this.onboardingSource.asObservable();
    deselect$ = this.onboardingOffSource.asObservable();
    add$ = this.onboardingAddSource.asObservable();

    select(event): void {
        this.onboardingSource.next(event);
    }

    getCurrentNode(): Observable<any> {
        return this.nodes$;
    }

    deselect(nodeID: number): void {
        this.onboardingOffSource.next(nodeID);
    }

    add(assignmentID: number) {
        this.onboardingAddSource.next(assignmentID);
    }
}
