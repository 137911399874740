import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';

@Component({
    selector: 'app-users-edit-meta',
    template: `
        <mat-form-field>
            <input
                    matInput
                    [type]="type"
                    [ngModel]="type === 'tel' ? (value | tel) : value"
                    [placeholder]="placeholder"
                    (ngModelChange)="value=$event"
                    [maxlength]="type === 'tel' ? 14 : 128">
        </mat-form-field>
        <button mat-raised-button (click)="save()">Save</button>
    `,
    styles: [`
        mat-form-field {
            width: 100%;
        }
    `]
})
export class UsersEditMetaComponent implements OnInit {
    value: string;
    key: string;
    uniqueId: string;
    placeholder: string;
    type: string

    constructor(
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<UsersEditMetaComponent>
    ) {
    }

    ngOnInit() {
        this.uniqueId = this.data.uniqueId;
        this.value = this.data.value;
        this.key = this.data.key;
        this.placeholder = this.data.placeholder;
        this.type = this.data.type;
    }

    save() {
        const data = {};
        data[this.key] = this.value;

        this.api.updateUser(this.uniqueId, data).subscribe((response: ResponseInterface) => {
            console.log(response);
            if (response.success) {
                this.dialog.close(this.value);
            }
        });
    }

}
