import {Component, OnDestroy, OnInit} from '@angular/core';
import {AssignmentsService} from '../../services/assignments.service';
import {ApiService} from '../../services/api.service';
import {ResponseInterface} from '../../interfaces/response.interface';
import {MatDialog, MatDialogRef, MatTabChangeEvent} from '@angular/material';
import {InsightCreateComponent} from './insight-create.component';
import {Subscription} from 'rxjs';
import {ConfirmDeleteComponent} from '../../components/confirm-delete/confirm-delete.component';
import {UsersService} from '../../services/users.service';

@Component({
    selector: 'app-insights',
    templateUrl: './insights.component.html',
    styleUrls: ['./insights.component.sass']
})
export class InsightsComponent implements OnInit, OnDestroy {

    public assignments = [];
    public insights = [];
    public currentAssignment: number;
    public isLoading = false;
    public options: Object = {
        key: 'dB8C6D5E2A-9H3E2I2B2C6B3B2B5B1F1phbndxD-11A-9B1vyz=='
    };

    private assignmentSubscription: Subscription;

    constructor(
        public assignmentService: AssignmentsService,
        public users: UsersService,
        private _api: ApiService,
        private _dialog: MatDialog
    ) {
    }

    ngOnInit() {
        // If the assignments were previously retrieved, attempt to get them
        if (this.assignmentService.assignments.length) {
            this.currentAssignment = this.assignmentService.assignments[0].id;
            // this.refreshInsights();
        }

        // If changes are made to the active assignment, update the data
        this.assignmentSubscription = this.assignmentService.assignment$.subscribe(result => {
            this.currentAssignment = result[0].id;
            // this.refreshInsights();
        });
    }

    ngOnDestroy() {
        this.assignmentSubscription.unsubscribe();
    }

    /**
     * Create a new insight
     * @param {number} insightID
     */
    create(insightID: number) {
        this.createDialog(insightID);
    }

    /**
     * Edit an existing insight by ID
     * @param {number} index
     * @param {number} childIndex
     */
    edit(index: number, childIndex: number = null) {
        if (childIndex !== null) {
            this.editDialog(this.insights[index].children[childIndex]);

            return;
        }

        this.editDialog(this.insights[index]);
    }

    /**
     * Delete an existing insight by ID
     * @param {number} insightID
     */
    delete(insightID: number, title: string) {

        const dialogRef = this._dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: { title: title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this._api.deleteInsight(insightID).subscribe((response: ResponseInterface) => {
                    console.log('delete insight response');
                    console.log(response);

                    if (response.success) {
                        this.refreshInsights();
                    }
                });
            }
        });
    }

    /**
     * Open the create dialog box
     * @param {any} insightID
     */
    createDialog(insightID = null) {
        const data = {
            assignmentID: this.currentAssignment,
            insightID
        };

        const dialogRef = this._dialog.open(InsightCreateComponent, {
            width: '900px',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.refreshInsights();
            }
        });
    }

    /**
     * Open the edit dialog box
     * @param data
     */
    editDialog(data: any) {
        const dialogRef = this._dialog.open(InsightCreateComponent, {
            width: '900px',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.refreshInsights();
            }
        });
    }

    /**
     * Refresh all insights by the current assignment
     */
    refreshInsights() {
        this._api.getInsightsByAssignment(this.currentAssignment).subscribe((response: ResponseInterface) => {
            this.insights = response.result;
            this.isLoading = false;
        });
    }

    /**
     * Change the current assignment
     * @param {MatTabChangeEvent} change
     */
    changeAssignment(change: MatTabChangeEvent) {

        this.isLoading = true;
        this.currentAssignment = this.assignmentService.assignments[change.index].id;
        this.refreshInsights();
    }
}
